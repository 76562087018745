import { combine, createStore, merge, sample } from 'effector'
import { clone } from 'ramda'

import * as api from '@gmini/chm-api-sdk'

export const uploadSectionItemFile =
  api.Instance.uploadSectionItemFile.createContext()
export const uploadSectionItemPending$ = uploadSectionItemFile.pending$
export const startUploadSectionItemFile =
  api.Instance.startUploadSectionItemFile.createContext()
export const startUploadSectionItemFilePending$ =
  startUploadSectionItemFile.pending$
export const deleteSectionItemFile =
  api.Instance.deleteSectionItemFile.createContext()
export const deleteSectionItemPending$ = deleteSectionItemFile.pending$
export const downloadSectionItemFile =
  api.Instance.downloadSectionItemFile.createContext()
export const downloadSectionItemFilePending$ = downloadSectionItemFile.pending$
export const getFile = api.Instance.downloadSectionItemFile.createContext()
export const fetchSectionItemFileList =
  api.Instance.fetchSectionItemFileList.createContext()
export const fetchSectionItemFileListPending$ =
  fetchSectionItemFileList.pending$

export const actionSectionItemFileEvents = merge([
  uploadSectionItemFile.doneData,
  deleteSectionItemFile.doneData,
])

export const fileById$ = createStore<Record<string, string>>({})
  .on(downloadSectionItemFile.done, (state, { result, params }) => {
    const next = clone(state)

    if (params?.fileId) {
      next[params.fileId] = result
    }

    return next
  })
  .on(deleteSectionItemFile.doneData, (state, { id }) => {
    const next = clone(state)

    delete next[id]

    return next
  })

type FileLinkById = Record<number, string>

fetchSectionItemFileList.doneData.watch(fileList =>
  Promise.all(fileList.map(({ id }) => getFile({ fileId: id }))),
)

export const fileLinkById$ = createStore<FileLinkById>({})
  .on(getFile.done, (state, { params, result }) => {
    const next = clone(state)

    next[params.fileId] = result

    return next
  })
  .on(deleteSectionItemFile.doneData, (state, { id }) => {
    const next = clone(state)

    delete next[id]

    return next
  })

export type FileListBySectionId = Record<string, api.Instance.SectionItemFile[]>

export const filesByTemplateSectionId$ = createStore<FileListBySectionId>({})
  .on(fetchSectionItemFileList.done, (state, { params, result }) => {
    const next = clone(state)

    next[params.templateSectionItemId] = result

    return next
  })
  .on(deleteSectionItemFile.doneData, (state, result) => {
    const next = clone(state)

    if (next[result.templateSectionItemId]?.[result.id]) {
      delete next[result.templateSectionItemId][result.id]
    }

    return next
  })

export const fileList$ = combine(
  { filesByTemplateSectionId$ },
  ({ filesByTemplateSectionId$ }) =>
    [...Object.values(filesByTemplateSectionId$)].flat(),
)

sample({
  source: fileList$,
  clock: downloadSectionItemFile.done,
  fn: (fileList, { params, result }) => ({
    link: result,
    filename: fileList?.find(({ id }) => id === params.fileId)?.name,
  }),
}).watch(({ filename, link }) => {
  const anchorLink = window.document.createElement('a')
  anchorLink.innerHTML = filename ? filename : ''
  anchorLink.href = link
  anchorLink.download = filename ? filename : ''
  window.document.body.appendChild(anchorLink)
  anchorLink.click()
  window.document.body.removeChild(anchorLink)
})

export const uploadSectionItemFiles = async (
  {
    instanceId,
    instanceVersion,
    templateSectionItemId,
  }: {
    instanceId: number
    instanceVersion: number
    templateSectionItemId: number
  },
  files: File[],
) => {
  const newInstance = await startUploadSectionItemFile({
    instanceId,
    instanceVersion,
    templateSectionItemId,
  })

  await Promise.all(
    files.map(file => {
      const formData = new FormData()
      formData.append('file', file, file.name)

      return uploadSectionItemFile({
        formData,
        instanceId: newInstance.id,
        instanceVersion: newInstance.version,
        templateSectionItemId,
      })
    }),
  )

  fetchSectionItemFileList({
    instanceId: newInstance.id,
    instanceVersion: newInstance.version,
    templateSectionItemId,
  })
}
