import {
  UnfoldLess,
  UnfoldMore,
  IconButton,
  Select,
  Calendar,
  SelectByGroupsMultiple,
} from '@gmini/ui-kit'
import { useState, useCallback } from 'react'

import { useStore } from 'effector-react'

import { Scrollbars } from '@gmini/components'

import * as api from '@gmini/chm-api-sdk'

import {
  AssigneeListItem,
  checkChangesArray,
  useAssignees,
} from '@gmini/helpers'

import { DetailsSummary } from '../../molecules/DetailsSummary'

import {
  DetailsContainer,
  DetailsFieldLabel,
  DetailsHeader,
  DetailsTitle,
  DetailsWrapper,
  DetailsField,
} from '../TemplateDetailsPanel/TemplateDetailsPanel.styled'

import { updateInstance } from '../../pages/EditInstancePage/EditInstance.store'

import {
  assigneeGroupList$,
  assigneeRoleList$,
  assigneeAllUserList$,
  assigneeCompanyList$,
} from '../../assigneeGroupList'

import {
  ProgressBar,
  Separator,
  DateBlock,
  DateBlockItem,
  ProgressDescriptionLabel,
  ProgressDescriptionValue,
  ProgressDescription,
  DetailsFields,
  InactiveTextArea,
} from './InstanceDetailsPanel.styled'

const mockOptionsUsers = [
  { name: 'Иванов И.И.', id: 'test1' },
  { name: 'Петров А.А.', id: 'test1' },
  { name: 'Васильев Т.К', id: 'test1' },
]

type InstanceDetailsPanelProps = {
  template: api.Template.TemplatePopulated
  instance: api.Instance.InstancePopulated
  disabled?: boolean
}

export const InstanceDetailsPanel = ({
  template,
  instance,
  disabled,
}: InstanceDetailsPanelProps) => {
  const { id, version, itemProperties } = instance

  const assigneeGroupList = useStore(assigneeGroupList$)
  const getAssignees = useAssignees({
    assigneeRoleList$,
    assigneeUserList$: assigneeAllUserList$,
    assigneeCompanyList$,
  })
  const assigneesInit = getAssignees(instance.assignees)
  const [assignees, setAssignees] = useState(assigneesInit)

  const [detailsCollapsed, setDetailsCollapsed] = useState(false)
  const questionOutOf =
    template.sections.reduce(
      (acc, item) => (item.items ? acc + item.items.length : acc),
      0,
    ) || 0

  const onUpdateInstance = useCallback(
    (params: Partial<api.Instance.UpdateParams>) => {
      updateInstance({ ...instance, id, version, ...params })
    },
    [id, instance, version],
  )

  const onChangeAssignee = useCallback(
    (value: Array<AssigneeListItem>) => {
      const existChanges = checkChangesArray(
        assigneesInit,
        value,
        item => item.assigneeId,
      )
      if (!existChanges) {
        return
      }

      onUpdateInstance({
        assignees: value,
      })
    },
    [assigneesInit, onUpdateInstance],
  )

  // TODO: РАССМОТРЕТЬ КОГДА СЕКЦИЙ БУДЕТ БОЛЬШЕ 1
  const answersIds = itemProperties.map(answer => answer.templateSectionItemId)

  const countSection =
    template.sections.filter(section =>
      section.items?.every(question => answersIds.includes(question.id)),
    ).length || 0

  return (
    <DetailsWrapper collapsed={detailsCollapsed}>
      <Scrollbars>
        <DetailsContainer collapsed={detailsCollapsed}>
          <DetailsHeader>
            <DetailsTitle collapsed={detailsCollapsed}>
              Детали чек-листа
            </DetailsTitle>
            <IconButton
              size='regular'
              onClick={() => setDetailsCollapsed(prev => !prev)}
            >
              {detailsCollapsed ? <UnfoldMore /> : <UnfoldLess />}
            </IconButton>
          </DetailsHeader>
          {!detailsCollapsed && (
            <>
              <DetailsSummary
                countSection={countSection}
                sectionOutOf={template.sections.length}
                questionCount={itemProperties.length}
                questionOutOf={questionOutOf}
                signCount={0}
                signOutOf={0}
                withBottomSeparator={false}
              />
              <ProgressBar
                value={(100 / questionOutOf) * itemProperties.length || 0}
              />
              <ProgressDescription>
                <ProgressDescriptionLabel>Выполнено</ProgressDescriptionLabel>
                <ProgressDescriptionValue>
                  {((100 / questionOutOf) * itemProperties.length || 0).toFixed(
                    0,
                  )}
                  %
                </ProgressDescriptionValue>
              </ProgressDescription>

              <Separator />
              <DetailsFields>
                {template.description && (
                  <DetailsField>
                    <DetailsFieldLabel>Описание</DetailsFieldLabel>
                    <InactiveTextArea>{template.description}</InactiveTextArea>
                  </DetailsField>
                )}

                <DetailsField>
                  <DetailsFieldLabel>Исполнитель</DetailsFieldLabel>
                  <Select
                    // eslint-disable-next-line no-empty-function
                    onChange={() => {}}
                    value={null}
                    placeholder='Выберите роль'
                    options={mockOptionsUsers}
                    getOptionLabel={(option: (typeof mockOptionsUsers)[0]) =>
                      option.name
                    }
                    disabled
                    colorScheme='white'
                    emptyOptionListMessage='Нет доступных совпадений'
                  />
                </DetailsField>

                <DetailsField>
                  <DetailsFieldLabel>Локация</DetailsFieldLabel>
                  <Select
                    // eslint-disable-next-line no-empty-function
                    onChange={() => {}}
                    value={null}
                    placeholder='Указать локацию'
                    options={mockOptionsUsers}
                    getOptionLabel={(option: (typeof mockOptionsUsers)[0]) =>
                      option.name
                    }
                    disabled
                    colorScheme='white'
                    emptyOptionListMessage='Нет доступных совпадений'
                  />
                </DetailsField>

                <DetailsField>
                  <DetailsFieldLabel>Запланирован на</DetailsFieldLabel>
                  <Select
                    // eslint-disable-next-line no-empty-function
                    onChange={() => {}}
                    value={null}
                    placeholder='Выберите дату'
                    options={mockOptionsUsers}
                    getOptionLabel={(option: (typeof mockOptionsUsers)[0]) =>
                      option.name
                    }
                    disabled
                    colorScheme='white'
                    emptyOptionListMessage='Нет доступных совпадений'
                  />
                </DetailsField>
              </DetailsFields>

              <DateBlock>
                <DateBlockItem>
                  <DetailsFieldLabel>Дата старта</DetailsFieldLabel>
                  <Select
                    // eslint-disable-next-line no-empty-function
                    onChange={() => {}}
                    value={null}
                    placeholder='...'
                    options={mockOptionsUsers}
                    getOptionLabel={(option: (typeof mockOptionsUsers)[0]) =>
                      option.name
                    }
                    disabled
                    colorScheme='white'
                    emptyOptionListMessage='Нет доступных совпадений'
                    icon={<Calendar color='rgba(53, 59, 96, 0.25)' />}
                  />
                </DateBlockItem>
                <DateBlockItem>
                  <DetailsFieldLabel>Дата выполнения</DetailsFieldLabel>
                  <Select
                    // eslint-disable-next-line no-empty-function
                    onChange={() => {}}
                    value={null}
                    placeholder='...'
                    options={mockOptionsUsers}
                    getOptionLabel={(option: (typeof mockOptionsUsers)[0]) =>
                      option.name
                    }
                    disabled
                    colorScheme='white'
                    emptyOptionListMessage='Нет доступных совпадений'
                    icon={<Calendar color='rgba(53, 59, 96, 0.25)' />}
                  />
                </DateBlockItem>
              </DateBlock>
              <Separator />
              <DetailsFieldLabel>Ответственный за чек-лист</DetailsFieldLabel>
              <SelectByGroupsMultiple
                onChange={setAssignees}
                onBlur={onChangeAssignee}
                onReset={() => {
                  setAssignees([])
                  onChangeAssignee([])
                }}
                value={assignees}
                placeholder='Выберите ответственного'
                groups={assigneeGroupList}
                getOptionLabel={(option: AssigneeListItem) => option.label}
                colorScheme='white'
                emptyOptionListMessage='Нет доступных совпадений'
                disabled={disabled}
              />
            </>
          )}
        </DetailsContainer>
      </Scrollbars>
    </DetailsWrapper>
  )
}
