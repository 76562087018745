/* eslint-disable react/display-name */
import {
  Button,
  ConfirmActionPopover,
  IconButton,
  PlusCircle,
  Tooltip,
  Update,
  Table,
  TableColumn,
  TableContentContainer,
} from '@gmini/ui-kit'
import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import moment from 'moment'

import { isNotEmpty, useDebounce } from '@gmini/utils'

import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'
import { useContextMenu } from '@gmini/common/lib/components/VersionSwitch/ContextMenu'

import { useStore, useStoreMap } from 'effector-react'

import { combine } from 'effector'

import { useHistory } from 'react-router'

import {
  ActionPanel,
  HighlightOffIcon,
  InfiniteScroll,
  Magnifier,
  NameBold,
  NoWrapDiv,
  SearchContainer,
  SearchInput,
  StatusContainer,
  StatusIndicator,
  TabContainer,
  TableContent,
  TooltipScrollbar,
} from '@gmini/components'

import { Template } from '@gmini/chm-api-sdk'

import { Auth } from '@gmini/sm-api-sdk'

import {
  AssigneeListItem,
  ChecklistTemplateListFiltersType,
  createAssigneesTooltipText,
  PROJECT_URN,
  queryParser,
  SEARCH_TL,
  useAssignees,
} from '@gmini/helpers'

import { templateTypes$ } from '../../templateTypes.store'

import { allUserList$, fetchAllUserListPending$ } from '../../user.store'

import {
  DEFAULT_DISPLAY_DATE_FORMAT,
  PROJECT_IS_NOT_SELECTED_ERROR,
  ZERO_SEARCH,
} from '../../../constants'

import { TemplateListAppliedFilters } from '../TemplateListAppliedFilters'

import { TemplateListFilterPanel } from '../TemplateListFilterPanel'

import { fetchAllowedFiltersPending$ } from '../TemplateListFilterPanel/model'

import {
  assigneeAllUserList$,
  assigneeCompanyList$,
  assigneeRoleList$,
} from '../../assigneeGroupList'

import { filterService } from '../../checklistTemplateFilter.store'

import {
  createTemplate,
  deleteTemplate,
  fetchTemplateList,
  fetchTemplateListWithEnrichDateFilter,
  FetchTemplateListWithPrepareDateRange,
  resetTemplateList,
  templateList$,
  templateTableService,
} from './model'

export const TemplateList = () => {
  const history = useHistory()

  const {
    appliedFilters: { appliedFilters$, useUpdateFilter },
  } = filterService
  const { update } = useUpdateFilter()
  const appliedFilters = useStore(appliedFilters$)
  const projectUrn = queryParser({ key: PROJECT_URN }) as string

  const [searchValue, setSearchValue] = useState(appliedFilters.filter)
  const [anchorElPopover, setAnchorElPopover] = useState<Element | null>(null)
  const [selectedTemplate, setSelectedTemplate] =
    useState<TemplateTableRow | null>(null)
  const [offset, setOffset] = useState(defaultOffset)

  const fetchTemplateListCb = useCallback(
    (params: FetchTemplateListWithPrepareDateRange) => {
      fetchTemplateListWithEnrichDateFilter({
        ...appliedFilters,
        projectUrn,
        ...params,
      })
    },
    [appliedFilters, projectUrn],
  )

  const allUserList = useStore(allUserList$)
  const debouncedFetch = useDebounce({
    handler: () => {
      const formattedSearchValue = searchValue.trim().toLowerCase()
      if (
        appliedFilters.filter?.trim().toLowerCase() === formattedSearchValue
      ) {
        return
      }
      resetTemplateList()
      setOffset(0)
      fetchTemplateListCb({ offset: 0, limit, filter: formattedSearchValue })

      formattedSearchValue
        ? update({ [SEARCH_TL]: formattedSearchValue })
        : update({ [SEARCH_TL]: '' })
    },
    delay: 500,
  })
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const tableContainerRefCurrent = tableContainerRef.current
  const templatePending = useStore(templatePending$)
  const templateTypes = useStore(templateTypes$)
  const fetchAllUserListPending = useStore(fetchAllUserListPending$)
  const [columns, setColumns] = useState(getTemplateListColumnOrderFromStorage)

  const { templateList, total } = useStoreMap({
    store: templateList$,
    keys: [searchValue.trim().toLowerCase()],
    fn: ({ byId$, ids$, totalTemplates$ }, [key]) => {
      const search = key || ZERO_SEARCH
      const idsList = ids$[search]
      if (idsList) {
        return {
          templateList: idsList.map(id => byId$[id]).filter(isNotEmpty),
          total: totalTemplates$ || 0,
        }
      }

      return { templateList: [], total: 0 }
    },
  })
  const getInitiators = useAssignees({
    assigneeRoleList$,
    assigneeUserList$: assigneeAllUserList$,
    assigneeCompanyList$,
  })

  useEffect(() => () => resetTemplateList(), [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => debouncedFetch(), [searchValue])

  useEffect(() => {
    setColumnsToStorage(columns)
  }, [columns])

  useEffect(() => {
    if (!projectUrn) {
      return
    }

    resetTemplateList()
    fetchTemplateListCb({ offset: 0, limit })
    setOffset(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUrn])

  useEffect(() => {
    if (!projectUrn || offset === 0) {
      return
    }

    fetchTemplateListCb({ offset, limit })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, projectUrn])

  const listIsNotOver = total > offset

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: TemplateTableRow
    event: MouseEvent
  }>([
    {
      title: 'Редактировать',
      onClick: props => {
        history.push({
          pathname: `/template/edit/${props.item.id}`,
          search: window.location.search,
        })
      },
      icon: Icon.EDIT,
    },
    {
      title: 'Создать копию',
      // eslint-disable-next-line no-empty-function
      onClick: props => {},
      icon: Icon.COPY,
      disabled: () => true,
    },
    {
      title: 'Архивировать',
      // eslint-disable-next-line no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      disabled: () => true,
    },
    {
      title: 'Удалить',
      onClick: props => {
        setAnchorElPopover(props.event.target as Element)
        setSelectedTemplate(props.item)
      },
      icon: Icon.DELETE,
    },
  ])

  const onDeleteTemplate = () => {
    if (!selectedTemplate) {
      return
    }

    deleteTemplate({
      id: selectedTemplate.id,
      version: selectedTemplate.version,
    }).then(() => {
      resetTemplateList()
      fetchTemplateListCb({ offset: 0, limit })
      setOffset(0)
    })
  }

  const onCreateTemplate = () => {
    history.push({
      pathname: '/template/create',
      search: window.location.search,
    })
  }

  const resetInfinityScroll = () => {
    tableContainerRefCurrent?.scrollTo(0, 0)
    resetTemplateList()
    fetchTemplateListCb({ offset: 0, limit })
    setOffset(0)
  }

  const handleClosePopover = () => {
    setAnchorElPopover(null)
  }

  const tableList = useMemo(
    (): TemplateTableRow[] =>
      templateList.map(template => ({
        ...template,
        templateTypeName: templateTypes.find(
          type => type.id === template.templateTypeId,
        )?.name,
        status: 'DONE',
        owner: allUserList.find(user => user.id === template.ownerId),
        initiators: getInitiators(template.initiators),
      })),
    [allUserList, getInitiators, templateList, templateTypes],
  )

  const onChangeFilter = useCallback(
    (filter: ChecklistTemplateListFiltersType) => {
      if (!projectUrn) {
        throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
      }

      resetTemplateList()
      fetchTemplateListCb({
        offset: 0,
        limit,
        projectUrn,
        ...filter,
      })
      setOffset(0)
    },
    [fetchTemplateListCb, projectUrn],
  )

  const isLoadingCol = useCallback(
    (col: TableColumn<TemplateTableRow>) => {
      if (col.field === 'owner') {
        return fetchAllUserListPending
      }

      return false
    },
    [fetchAllUserListPending],
  )

  return (
    <TabContainer>
      <ConfirmActionPopover
        message={
          <>
            Вы уверены, что хотите навсегда удалить{' '}
            <NameBold>{selectedTemplate?.name}?</NameBold> Все ссылки на ресурсы
            будут удалены. Это действие не может быть отменено.
          </>
        }
        title='Удалить шаблон'
        anchorEl={anchorElPopover}
        handleClose={handleClosePopover}
        actions={
          <>
            <Button
              size='regular'
              color='secondary'
              onClick={handleClosePopover}
            >
              Отмена
            </Button>
            <Button
              color='warning'
              onClick={() => {
                onDeleteTemplate()
                handleClosePopover()
              }}
              size='regular'
            >
              Удалить
            </Button>
          </>
        }
      />
      <TableContentContainer>
        <TemplateListFilterPanel
          projectUrn={projectUrn}
          onChange={onChangeFilter}
        />
        <TableContent>
          <ActionPanel>
            <SearchContainer style={{ marginRight: 'auto' }}>
              <SearchInput
                value={searchValue}
                onChange={event => setSearchValue(event.target.value)}
                placeholder='Поиск'
                width='230px'
              />
              <Magnifier />
              {searchValue ? (
                <HighlightOffIcon onClick={() => setSearchValue('')} />
              ) : null}
            </SearchContainer>

            <TemplateListAppliedFilters onChange={onChangeFilter} />

            <Tooltip placement='top' title='Обновить список шаблонов'>
              <IconButton
                onClick={resetInfinityScroll}
                disabled={templatePending}
              >
                <Update color='rgba(53, 59, 96, 0.5)' />
              </IconButton>
            </Tooltip>

            <Button
              onClick={onCreateTemplate}
              leftIcon={<PlusCircle width='24px' height='24px' />}
              disabled={templatePending}
            >
              Создать шаблон чек-листа
            </Button>
          </ActionPanel>
          {!templatePending && <ContextMenu />}
          <InfiniteScroll
            hasMore={tableList.length !== 0 && listIsNotOver}
            next={() => setOffset(prevValue => prevValue + limit)}
            triggersObserve={[tableList]}
          >
            <Table
              columns={columns}
              rows={tableList}
              tableService={templateTableService}
              onChangeColumns={setColumns}
              pending={templatePending}
              getRowKey={row => row.id}
              activeRowKey={ctxMenu.item?.item.id}
              totalRows={total || 0}
              onRowCtxMenu={(e, item) => {
                e.preventDefault()

                setCtxMenu({
                  coords: { x: e.clientX, y: e.clientY },
                  item: { item, event: e },
                })
              }}
              onClick={(e, item) => {
                history.push({
                  pathname: `/template/edit/${item.id}`,
                  search: window.location.search,
                })
              }}
              isLoadingCol={isLoadingCol}
            />
          </InfiniteScroll>
        </TableContent>
      </TableContentContainer>
    </TabContainer>
  )
}

const defaultOffset = 0
const limit = 20

const mapStatusDescriptionChecklist = {
  DONE: 'Готов',
  DRAFT: 'Черновик',
}

const mapStatusColorChecklist = {
  DONE: '#42AB85',
  DRAFT: '#A2A3B7',
}

type TemplateStatus = 'DONE' | 'DRAFT'

type TemplateTableRow = Omit<Template.Template, 'initiators'> & {
  status: TemplateStatus
  templateTypeName?: string
  owner?: Auth.UserData
  initiators: Array<AssigneeListItem>
}

const columns: TableColumn<TemplateTableRow>[] = [
  {
    name: 'ID',
    field: 'id',
    thContent: 'ID',
    type: 'number',
    visible: true,
  },
  {
    renderCell: ({ row }) => (
      <StatusContainer>
        <StatusIndicator
          style={{
            background: mapStatusColorChecklist[row.status],
          }}
        />
        {mapStatusDescriptionChecklist[row.status]}
      </StatusContainer>
    ),
    name: 'Статус',
    field: 'status',
    visible: true,
  },
  {
    renderCell: ({ row }) => (
      <>
        {row.name.length > 25 ? (
          <Tooltip placement='bottom' title={row.name}>
            <>{row.name.substring(0, 25)}...</>
          </Tooltip>
        ) : (
          row.name
        )}
      </>
    ),
    cellStyle: { wordBreak: 'break-word' },
    style: { width: 'auto' },
    name: 'Название',
    field: 'name',
    visible: true,
  },
  {
    renderCell: ({ row }) => <>{row.version ? `Версия ${row.version}` : '-'}</>,
    cellStyle: {
      whiteSpace: 'nowrap',
    },
    name: 'Версия',
    field: 'version',
    visible: true,
  },
  {
    renderCell: ({ row }) => (
      <>{row.templateTypeId ? row.templateTypeName : 'Не задана'}</>
    ),
    name: 'Дисциплина',
    field: 'templateTypeId',
    visible: true,
  },
  {
    renderCell: ({ row }) => (
      <>{moment(row.createdAt).format(DEFAULT_DISPLAY_DATE_FORMAT)}</>
    ),
    name: 'Создан',
    field: 'createdAt',
    visible: true,
  },
  {
    renderCell: ({ row }) =>
      row.updatedAt
        ? moment(row.updatedAt).format(DEFAULT_DISPLAY_DATE_FORMAT)
        : 'Не изменялся',
    name: 'Изменен',
    field: 'updatedAt',
    visible: true,
  },
  {
    field: 'initiators',
    renderCell: ({ row }) => {
      if (!row.initiators.length) {
        return <>Не назначен</>
      }
      const { initiators } = row
      const extraInitiatorsCount =
        initiators.length > 1 ? initiators.length - 1 : null

      const firstInitiatorLabel = initiators[0].label

      return (
        <Tooltip
          title={
            extraInitiatorsCount ? (
              <TooltipScrollbar>
                {createAssigneesTooltipText(initiators)}
              </TooltipScrollbar>
            ) : (
              ''
            )
          }
          placement='top'
        >
          <NoWrapDiv>
            {firstInitiatorLabel.length > 25 ? (
              <>{firstInitiatorLabel.substring(0, 25)}...</>
            ) : (
              firstInitiatorLabel
            )}
            {extraInitiatorsCount && ` +${extraInitiatorsCount}`}
          </NoWrapDiv>
        </Tooltip>
      )
    },
    name: 'Инициатор чек-листа',
    visible: true,
    cellStyle: { width: '145px', overflow: 'hidden' },
    style: { width: '155px' },
  },
  {
    renderCell: ({ row }) => {
      if (!row.owner) {
        return <>Не найден</>
      }

      return <>{row.owner.name}</>
    },
    name: 'Автор',
    field: 'owner',
    visible: true,
  },
  {
    name: 'Экземпляры',
    field: 'instanceTotal',
    visible: true,
  },
  {
    name: 'Количество секций',
    field: 'sectionsTotal',
    thContent: 'Кол-во секций',
    visible: true,
  },
  {
    name: 'Количество вопросов',
    field: 'itemsTotal',
    thContent: 'Кол-во вопросов',
    visible: true,
  },
]

type ColumnSettings = {
  field: string
  visible: boolean
}

const getTemplateListColumnOrderFromStorage =
  (): TableColumn<TemplateTableRow>[] => {
    const data = localStorage.getItem('templateListColumnOrder')
    if (typeof data === 'string') {
      try {
        const parsedData = JSON.parse(data) as ColumnSettings[]

        return columns
          .slice()
          .sort((a, b) => {
            const aIdx = parsedData.findIndex(s => s.field === a.field)
            const bIdx = parsedData.findIndex(s => s.field === b.field)

            // В случае если в localStorage не было настройки колонки (например: в коде добавили новую)
            if (aIdx < 0 || bIdx < 0) {
              return 0
            }

            return aIdx - bIdx
          })
          .map(col => {
            const colFromLocalStorage = parsedData.find(
              ({ field }) => field === col.field,
            )

            return {
              ...col,
              visible:
                colFromLocalStorage === undefined
                  ? true
                  : colFromLocalStorage.visible,
            }
          })
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      }
    }
    return columns
  }

const setColumnsToStorage = (next: TableColumn<TemplateTableRow>[]) => {
  localStorage.setItem(
    'templateListColumnOrder',
    JSON.stringify(next.map(({ field, visible }) => ({ field, visible }))),
  )
}

export const templatePending$ = combine(
  [
    createTemplate.pending$,
    fetchTemplateList.pending$,
    deleteTemplate.pending$,
    fetchAllowedFiltersPending$,
  ],
  pendings => pendings.some(Boolean),
)

export type TemplateCtxMenuValue = {
  templateID?: string
}
