import * as api from '@gmini/chm-api-sdk'
import * as ismApi from '@gmini/ism-api-sdk'
import { DateFilterItemsCode, filterDateEnrichment } from '@gmini/helpers'
import { createEffect, createEvent, merge } from 'effector'
import { createTableService } from '@gmini/ui-kit'

import { createTemplateListService } from '../../createTemplateListService'
import { fetchAllowedFilters } from '../TemplateListFilterPanel/model'

export const {
  createTemplate,
  templateList$,
  fetchTemplateList,
  deleteTemplate,
  resetTemplateList,
  deleteTemplatePending$,
  createTemplatePending$,
} = createTemplateListService({
  createTemplateApi: api.Template.createTemplate,
  deleteTemplateApi: api.Template.deleteTemplate,
  fetchTemplateListApi: api.Template.fetchList,
})

export const addIssueTemplateToIds = createEvent<{
  search: string
  id: number
}>()

merge([deleteTemplate.doneData, createTemplate.doneData]).watch(
  ({ projectUrn }) => {
    fetchAllowedFilters({ projectUrn })
  },
)

export type FetchTemplateListWithPrepareDateRange = Omit<
  api.Template.FetchListParams,
  'initiators'
> & {
  updatedDateCode?: DateFilterItemsCode | null
  createdDateCode?: DateFilterItemsCode | null
  initiators?: ismApi.Assignee[] | null
}

export const fetchTemplateListWithEnrichDateFilter = createEffect<
  FetchTemplateListWithPrepareDateRange,
  unknown
>()

fetchTemplateListWithEnrichDateFilter.use(
  ({
    updatedDateRange,
    createdDateRange,
    updatedDateCode,
    createdDateCode,
    initiators,
    ...otherProps
  }) => {
    const currentDate = new Date()

    const { enrichedUpdatedDateRange, enrichedCreatedDateRange } =
      filterDateEnrichment(currentDate, {
        updatedDateRange,
        createdDateRange,
        updatedDateCode,
        createdDateCode,
      })

    const prepInitiators = initiators?.map(initiators => ({
      source: initiators.source,
      initiatorId: initiators.assigneeId,
    }))

    fetchTemplateList({
      ...otherProps,
      updatedDateRange: enrichedUpdatedDateRange,
      createdDateRange: enrichedCreatedDateRange,
      initiators: JSON.stringify(prepInitiators),
    })
  },
)

export const templateTableService = createTableService()
