import {
  HelpRounded,
  IconButton,
  TextField as TextFieldInit,
} from '@gmini/ui-kit'
import styled from 'styled-components'
import { CircularProgress as CircularProgressInit } from '@gmini/ui-kit/lib/CircularProgress'

export const Content = styled.div`
  overflow-y: auto;
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 22px;
`

export const MessageContent = styled.div`
  font-size: 13px;
  line-height: 20px;
  font-family: Roboto, sans-serif;
  color: #000335;
  margin-bottom: 6px;
  display: flex;
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 60px 24px 16px 24px;
  width: 100%;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px 24px 34px 24px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.div`
  font-size: 20px;
  line-height: 21px;
  color: #000335;
  letter-spacing: 0.1px;
`

export const RequiredMessageIndicator = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  color: #de4242;
  margin-left: 4px;
  margin-right: 6px;
`

export const CloseButton = styled(IconButton)`
  && path {
    fill: #353b60;
  }
`

export const HelpIcon = styled(HelpRounded)`
  & path {
    transition: 0.2s ease-out fill;
  }

  &:hover path {
    fill: rgba(53, 59, 96, 0.5);
  }
`

export const GroupOption = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: rgba(0, 3, 53, 0.5);
  border-bottom: 1px solid #e5e7f1;
  margin: 0 16px;
`

export const CircularProgress = styled(CircularProgressInit)`
  margin-left: 5px;
`

export const InactiveTextArea = styled.div`
  padding: 9.5px 8px;
  background: rgba(53, 59, 96, 0.05);
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border-radius: 4px;
  color: #353b60;
  word-break: break-word;
`

export const AssigneeFieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const AssigneeField = styled.div`
  width: 272px;
`

export const ShowMore = styled.div`
  display: inline-block;
  color: #4c5ecf;
  margin-left: 5px;

  &:hover {
    color: #2235aa;
  }
`

export const TooltipTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
`

export const TooltipTitleText = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
`

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px 0;
`

export const TextField = styled(TextFieldInit)`
  && {
    &:disabled {
      background: rgba(53, 59, 96, 0.05);
    }
  }

  &::placeholder {
    color: rgba(0, 3, 53, 0.4);
  }
`
