import styled, { css } from 'styled-components'

import { Button as ButtonInit, Document as DocumentInit } from '@gmini/ui-kit'

export const Container = styled.div`
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  position: relative;
`

export const NameWrapper = styled.div`
  display: flex;
  gap: 12px;
`

export const Name = styled.div``

export const Description = styled.div`
  margin-bottom: 24px;
  line-height: 131%;
`

export const TitleText = styled.div``

export const Title = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 138%;
  letter-spacing: 0.15px;
  color: #00003d;
  margin-bottom: 12px;
`

export const ActionList = styled.div`
  padding: 7px 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 4px;
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  z-index: 1;
  min-width: 202px;
`

export const ActionListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 16px;
  font-weight: 400;
  gap: 12px;
  font-size: 16px;
  color: #000335;
  user-select: none;

  && svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background: #f4f4f8;
    cursor: pointer;
  }
`

export const Body = styled.div`
  border-top: 1px solid #e5e7f1;
  padding: 0 24px 0 36px;
`

export const IndexNumber = styled(Title)`
  width: 28px;
  display: inline-block;
`
export const AnswerBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 15px;
`

export const ButtonWrapper = styled.div`
  position: relative;
  margin-left: auto;
`

type ButtonProps = {
  pressed?: boolean
}

export const Button = styled(ButtonInit)`
  ${(props: ButtonProps) =>
    props.pressed &&
    css`
      && {
        background: rgba(53, 59, 96, 0.2);
      }
    `}
`

export const HiddenInput = styled.input`
  display: none;
`

type FileWrapperProps = {
  clickable?: boolean
}

export const FileWrapper = styled.div`
  display: flex;
  align-items: center;

  ${(props: FileWrapperProps) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}
`

export const DocumentTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #00003d;
  letter-spacing: 0.15px;
  margin: 0 8px;
  display: grid;
`

export const FileUploadAt = styled.div`
  color: rgba(53, 59, 96, 0.6);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-left: auto;
  margin-right: 24px;
`

export const BodyItem = styled.div`
  padding: 24px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #e5e7f1;
  }
`

export const Document = styled(DocumentInit)`
  width: 24px;
  height: 24px;
  min-width: 24px;
`
