import { AppliedFilters } from '@gmini/components'
import {
  CREATED_DATE_CODE_TL,
  ChecklistTemplateListFiltersType,
  ChecklistTemplateUrlKeyType,
  INITIATORS_TL,
  OWNER_IDS_TL,
  TEMPLATE_TYPE_IDS_TL,
  UPDATED_DATE_CODE_TL,
  appliedDateFilterItemsCodesMap,
  useAppliedFilterAssigneeData,
  useAppliedFilterDateData,
  useAppliedFilterTypeData,
  useAppliedFilterUserData,
} from '@gmini/helpers'
import { useStore } from 'effector-react'
import { useMemo } from 'react'

import { SHORT_DISPLAY_DATE_FORMAT } from '../../../constants'
import { templateTypes$ } from '../../templateTypes.store'

import { filterService } from '../../checklistTemplateFilter.store'
import { userList$ } from '../../user.store'

type TemplateListAppliedFiltersProps = {
  onChange?: (filter: ChecklistTemplateListFiltersType) => void
}

export const TemplateListAppliedFilters = ({
  onChange,
}: TemplateListAppliedFiltersProps) => {
  const {
    appliedFilters: { useUpdateFilter, appliedFilters$ },
  } = filterService
  const templateTypes = useStore(templateTypes$)
  const userList = useStore(userList$)
  const filters = useStore(appliedFilters$)
  const { update } = useUpdateFilter()

  const preparedTemplateTypes = useAppliedFilterTypeData({
    name: 'Дисциплина',
    urlKey: TEMPLATE_TYPE_IDS_TL,
    typeList: templateTypes,
  })

  const preparedOwners = useAppliedFilterUserData({
    name: 'Автор',
    urlKey: OWNER_IDS_TL,
    userList,
    value: filters.ownerIds || [],
  })

  const preparedAssignee = useAppliedFilterAssigneeData({
    name: 'Инициатор',
    urlKey: INITIATORS_TL,
    value: filters.initiators,
  })

  const preparedUpdatedDate = useAppliedFilterDateData({
    dateFormat: SHORT_DISPLAY_DATE_FORMAT,
    urlKeyCode: UPDATED_DATE_CODE_TL,
    name: 'Изменен',
    value: filters.updatedDateCode ? [filters.updatedDateCode] : [],
    valueName: filters.updatedDateCode
      ? appliedDateFilterItemsCodesMap[filters.updatedDateCode]
      : '',
  })

  const preparedCreatedDate = useAppliedFilterDateData({
    dateFormat: SHORT_DISPLAY_DATE_FORMAT,
    urlKeyCode: CREATED_DATE_CODE_TL,
    name: 'Создан',
    value: filters.createdDateCode ? [filters.createdDateCode] : [],
    valueName: filters.createdDateCode
      ? appliedDateFilterItemsCodesMap[filters.createdDateCode]
      : '',
  })

  const onChangeHandler = (filter: ChecklistTemplateUrlKeyType) => {
    const [[key]] = Object.entries(filter)
    update({ [key]: null })
    const filters = appliedFilters$.getState()
    onChange?.(filters)
  }

  const appliedFilters = useMemo(
    () =>
      [
        preparedTemplateTypes,
        preparedUpdatedDate,
        preparedCreatedDate,
        preparedOwners,
        preparedAssignee,
      ].filter(({ value }) => value.length),
    [
      preparedTemplateTypes,
      preparedUpdatedDate,
      preparedCreatedDate,
      preparedOwners,
      preparedAssignee,
    ],
  )

  return <AppliedFilters onChange={onChangeHandler} items={appliedFilters} />
}
