import { createProjectListService } from '@gmini/helpers'
import * as smApi from '@gmini/sm-api-sdk'

import { filterService as checklistFilterService } from '../../checklistFilter.store'
import { filterService as checklistTemplateFilterService } from '../../checklistTemplateFilter.store'

export const { fetchProjectList, fetchProjectListPending$, projectList$ } =
  createProjectListService({
    fetchProjectListApi: smApi.Project.fetchList,
  })

export const filterService = [
  checklistFilterService,
  checklistTemplateFilterService,
]
