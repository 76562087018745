/* eslint-disable react/display-name */
import {
  Button,
  ConfirmActionPopover,
  IconButton,
  PlusCircle,
  Tooltip,
  Update,
  Table,
  TableColumn,
  TableContentContainer,
} from '@gmini/ui-kit'
import { isNotEmpty, useDebounce } from '@gmini/utils'
import { useSnackbar } from 'notistack'
import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { userInfo$ } from '@gmini/common'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'
import { useContextMenu } from '@gmini/common/lib/components/VersionSwitch/ContextMenu'

import { useStore, useStoreMap } from 'effector-react'

import { combine } from 'effector'

import moment from 'moment'
import { useHistory } from 'react-router'

import {
  ActionPanel,
  EntityCreatedSnackbar,
  HighlightOffIcon,
  InfiniteScroll,
  Magnifier,
  NameBold,
  NoWrapDiv,
  SearchContainer,
  SearchInput,
  StatusContainer,
  StatusIndicator,
  TabContainer,
  TableContent,
  TooltipScrollbar,
} from '@gmini/components'

import * as smApi from '@gmini/sm-api-sdk'

import * as api from '@gmini/chm-api-sdk'

import { useKeycloak } from '@react-keycloak/web'

import {
  AssigneeListItem,
  ChecklistListFiltersType,
  createAssigneesTooltipText,
  PROJECT_URN,
  queryParser,
  SEARCH_IL,
  useAssignees,
} from '@gmini/helpers'

import { templateTypes$ } from '../../templateTypes.store'

import { CreateChecklistPopup } from '../CreateChecklistPopup'

import { allUserList$, fetchAllUserListPending$ } from '../../user.store'

import {
  DEFAULT_DISPLAY_DATE_FORMAT,
  PROJECT_IS_NOT_SELECTED_ERROR,
  ZERO_SEARCH,
} from '../../../constants'

import { FormValue } from '../CreateChecklistPopup/types'

import {
  keycloakAdminRole,
  keycloakAppResourceName,
} from '../../../config/keycloakConfig'

import {
  instanceStatusListByStatus$,
  PreparedInstanceStatus,
} from '../../instanceStatus'

import {
  assigneeAllUserList$,
  assigneeCompanyList$,
  assigneeRoleList$,
} from '../../assigneeGroupList'

import { InstanceListFilterPanel } from '../InstanceListFilterPanel'

import { InstanceListAppliedFilters } from '../InstanceListAppliedFilters'

import { fetchAllowedFiltersPending$ } from '../InstanceListFilterPanel/model'

import { filterService } from '../../checklistFilter.store'

import {
  addInstanceToIds,
  createInstance,
  fetchInstanceList,
  fetchInstanceListWithEnrichDateFilter,
  FetchInstanceListWithPrepareDateRange,
  instanceList$,
  removeInstance,
  resetInstanceList,
} from './InstanceList.store'

import { LinearProgressBar } from './InstanceList.styled'

import { matchInstanceToFilters } from './matchInstanceToFilters'
import { instanceTableService } from './model'

type InstanceListProps = {
  projectList: smApi.Project[]
}

export const InstanceList = ({ projectList }: InstanceListProps) => {
  const history = useHistory()

  const {
    appliedFilters: { appliedFilters$, useUpdateFilter },
  } = filterService
  const appliedFilters = useStore(appliedFilters$)
  const { update } = useUpdateFilter()

  const searchFromUrl = (queryParser({ key: SEARCH_IL }) as string) || ''
  const projectUrn = queryParser({ key: PROJECT_URN }) as string

  const [searchValue, setSearchValue] = useState(searchFromUrl)
  const [anchorElPopover, setAnchorElPopover] = useState<Element | null>(null)
  const [openedSelectTemplatePopup, setOpenedSelectTemplatePopup] =
    useState(false)
  const instanceStatusListByStatus = useStore(instanceStatusListByStatus$)

  const fetchInstanceListCb = useCallback(
    (params: FetchInstanceListWithPrepareDateRange) => {
      fetchInstanceListWithEnrichDateFilter({
        ...appliedFilters,
        projectUrn,
        ...params,
      })
    },
    [appliedFilters, projectUrn],
  )

  const [selectedInstance, setSelectedInstance] =
    useState<InstanceTableRow | null>(null)

  const [offset, setOffset] = useState(defaultOffset)
  const debouncedFetch = useDebounce({
    handler: () => {
      const formattedSearchValue = searchValue.trim().toLowerCase()
      if (searchFromUrl?.trim().toLowerCase() === formattedSearchValue) {
        return
      }
      resetInstanceList()
      setOffset(0)
      fetchInstanceListCb({ offset: 0, limit, filter: formattedSearchValue })

      formattedSearchValue
        ? update({ [SEARCH_IL]: formattedSearchValue })
        : update({ [SEARCH_IL]: '' })
    },
    delay: 500,
  })
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const tableContainerRefCurrent = tableContainerRef.current
  const instancePending = useStore(instancePending$)
  const templateTypes = useStore(templateTypes$)
  const allUserList = useStore(allUserList$)
  const userInfo = useStore(userInfo$)
  const fetchAllUserListPending = useStore(fetchAllUserListPending$)
  const [columns, setColumns] = useState(getInstanceListColumnOrderFromStorage)
  const { instanceList, total } = useStoreMap({
    store: instanceList$,
    keys: [searchValue.trim().toLowerCase()],
    fn: ({ byId$, ids$, totalInstances$ }, [val]) => {
      const search = val || ZERO_SEARCH
      const idsList = ids$[search]
      if (idsList) {
        return {
          instanceList: idsList.map(id => byId$[id]).filter(isNotEmpty),
          total: totalInstances$ || 0,
        }
      }

      return { instanceList: [], total: 0 }
    },
  })
  const getAssignees = useAssignees({
    assigneeRoleList$,
    assigneeUserList$: assigneeAllUserList$,
    assigneeCompanyList$,
  })

  const listIsNotOver = total > offset

  const { keycloak } = useKeycloak()

  const isAdmin = keycloak.hasResourceRole(
    keycloakAdminRole,
    keycloakAppResourceName,
  )

  useEffect(() => () => resetInstanceList(), [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => debouncedFetch(), [searchValue])

  useEffect(() => {
    setColumnsToStorage(columns)
  }, [columns])

  useEffect(() => {
    if (!projectUrn) {
      return
    }

    resetInstanceList()
    fetchInstanceListCb({ offset: 0, limit })
    setOffset(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUrn])

  useEffect(() => {
    if (!projectUrn || offset === 0) {
      return
    }

    fetchInstanceListCb({ offset, limit })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, projectUrn])

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: InstanceTableRow
    event: MouseEvent
  }>([
    {
      title: 'Редактировать',
      onClick: props => {
        history.push({
          pathname: `/instance/edit/${props.item.id}`,
          search: window.location.search,
        })
      },
      icon: Icon.EDIT,
    },
    {
      title: 'Создать копию',
      // eslint-disable-next-line no-empty-function
      onClick: props => {},
      icon: Icon.COPY,
      disabled: () => true,
    },
    {
      title: 'Архивировать',
      // eslint-disable-next-line no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      disabled: () => true,
    },
    {
      title: 'Удалить',
      onClick: props => {
        setAnchorElPopover(props.event.target as Element)
        setSelectedInstance(props.item)
      },
      icon: Icon.DELETE,
      show: ({ item }) =>
        item.status?.status === api.InstanceStatus.Enum.FINISHED
          ? isAdmin
          : true,
    },
  ])

  const onRemoveInstance = () => {
    if (!selectedInstance) {
      return
    }
    removeInstance({
      id: selectedInstance.id,
      version: selectedInstance.version,
    })
    resetInstanceList()
    fetchInstanceListCb({ offset: 0, limit })
    setOffset(0)
  }

  const resetInfinityScroll = () => {
    tableContainerRefCurrent?.scrollTo(0, 0)
    resetInstanceList()
    fetchInstanceListCb({ offset: 0, limit })
    setOffset(0)
  }

  const handleClosePopover = () => {
    setAnchorElPopover(null)
  }

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const notifyCreatedInstance = useCallback(
    (
      instance: api.Instance.InstancePopulated,
      isMatchIssueToFilters: boolean,
    ) => {
      let infoText: string | null =
        'Измените параметры фильтрации для отображения данного чек-листа в общем списке.'

      if (isMatchIssueToFilters) {
        infoText = null
      }

      enqueueSnackbar('', {
        content: key => (
          <EntityCreatedSnackbar
            id={key}
            message={`Новый чек-лист “${instance.name}” успешно создан.`}
            onClose={() => closeSnackbar()}
            infoText={infoText}
          />
        ),
      })
    },
    [closeSnackbar, enqueueSnackbar],
  )

  const onCreateChecklist = useCallback(
    async (data: FormValue) => {
      try {
        const { assignees, name, project, template } = data

        if (!project) {
          throw new Error('Не выбран проект')
        }
        setOpenedSelectTemplatePopup(false)
        if (template) {
          const instance = await createInstance({
            templateVersion: template.version,
            templateId: template.id,
            name,
            description: '',
            assignees,
            projectUrn: project.urn,
          })

          const isMatchInstanceToFilters = matchInstanceToFilters(
            instance,
            appliedFilters,
          )

          notifyCreatedInstance(instance, isMatchInstanceToFilters)

          if (isMatchInstanceToFilters) {
            addInstanceToIds({ id: instance.id, search: appliedFilters.filter })
          }
        }
      } catch (error) {
        console.error('error :>> ', error)
      }
    },
    [appliedFilters, notifyCreatedInstance],
  )

  const tableList = useMemo(
    (): InstanceTableRow[] =>
      instanceList.map(instance => ({
        ...instance,
        assignees: getAssignees(instance.assignees),
        company: '',
        issues: 0,
        progress: 100,
        signatures: 0,
        templateTypeName: templateTypes.find(
          type => type.id === instance.templateTypeId,
        )?.name,
        owner: allUserList.find(user => user.id === instance.ownerId),
        status: instanceStatusListByStatus[instance.status],
      })),
    [
      instanceList,
      getAssignees,
      templateTypes,
      allUserList,
      instanceStatusListByStatus,
    ],
  )

  const onChangeFilter = useCallback(
    (filter: ChecklistListFiltersType) => {
      if (!projectUrn) {
        throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
      }

      resetInstanceList()
      fetchInstanceListCb({
        offset: 0,
        limit,
        ...filter,
      })

      setOffset(0)
    },
    [fetchInstanceListCb, projectUrn],
  )

  const isLoadingCol = useCallback(
    (col: TableColumn<InstanceTableRow>) => {
      if (col.field === 'assignees' || col.field === 'owner') {
        return fetchAllUserListPending
      }

      return false
    },
    [fetchAllUserListPending],
  )

  if (!userInfo) {
    return null
  }

  return (
    <TabContainer>
      {openedSelectTemplatePopup && (
        <CreateChecklistPopup
          onClose={() => setOpenedSelectTemplatePopup(false)}
          open={openedSelectTemplatePopup}
          onCreateChecklist={onCreateChecklist}
          projectUrn={projectUrn}
          projectList={projectList}
          lockProjectSelect={!!projectUrn}
        />
      )}

      <ConfirmActionPopover
        title='Удалить чек-лист'
        message={
          <>
            Вы уверены, что хотите навсегда удалить{' '}
            <NameBold>{selectedInstance?.name}?</NameBold> Все ссылки на ресурсы
            будут удалены. Это действие не может быть отменено.
          </>
        }
        anchorEl={anchorElPopover}
        handleClose={handleClosePopover}
        actions={
          <>
            <Button
              size='regular'
              color='secondary'
              onClick={handleClosePopover}
            >
              Отменить
            </Button>
            <Button
              color='warning'
              onClick={() => {
                onRemoveInstance()
                handleClosePopover()
              }}
              size='regular'
            >
              Удалить
            </Button>
          </>
        }
      />
      <TableContentContainer>
        <InstanceListFilterPanel
          projectUrn={projectUrn}
          onChange={onChangeFilter}
        />
        <TableContent>
          <ActionPanel>
            <SearchContainer>
              <SearchInput
                value={searchValue}
                onChange={event => {
                  setSearchValue(event.target.value)
                }}
                placeholder='Поиск'
                width='230px'
              />
              <Magnifier />
              {searchValue ? (
                <HighlightOffIcon onClick={() => setSearchValue('')} />
              ) : null}
            </SearchContainer>

            <InstanceListAppliedFilters onChange={onChangeFilter} />

            <Tooltip placement='top' title='Обновить список чек-листов'>
              <IconButton
                onClick={resetInfinityScroll}
                disabled={instancePending}
              >
                <Update color='rgba(53, 59, 96, 0.5)' />
              </IconButton>
            </Tooltip>

            <Button
              data-test-id='createChecklist'
              onClick={() => setOpenedSelectTemplatePopup(true)}
              leftIcon={<PlusCircle width='24px' height='24px' />}
              disabled={instancePending}
            >
              Создать чек-лист
            </Button>
          </ActionPanel>

          {!instancePending && <ContextMenu />}

          <InfiniteScroll
            hasMore={tableList.length !== 0 && listIsNotOver}
            next={() => setOffset(prevValue => prevValue + limit)}
            triggersObserve={[tableList]}
          >
            <Table
              columns={columns}
              rows={tableList}
              tableService={instanceTableService}
              onRowCtxMenu={(e, item) => {
                e.preventDefault()

                setCtxMenu({
                  coords: { x: e.clientX, y: e.clientY },
                  item: { item, event: e },
                })
              }}
              totalRows={total || 0}
              pending={instancePending}
              activeRowKey={ctxMenu.item?.item.id}
              getRowKey={row => row.id}
              onChangeColumns={setColumns}
              onClick={(e, item) => {
                history.push({
                  pathname: `/instance/edit/${item.id}`,
                  search: window.location.search,
                })
              }}
              isLoadingCol={isLoadingCol}
            />
          </InfiniteScroll>
        </TableContent>
      </TableContentContainer>
    </TabContainer>
  )
}

const defaultOffset = 0
const limit = 20

type InstanceTableRow = Omit<api.Instance.Instance, 'status' | 'assignees'> & {
  assignees: Array<AssigneeListItem>
  company: string
  signatures: number
  issues: number
  progress: number
  templateTypeName?: string
  owner?: smApi.Auth.UserData
  status?: PreparedInstanceStatus | null
}

const columns: TableColumn<InstanceTableRow>[] = [
  {
    field: 'id',
    name: 'ID',
    thContent: 'ID',
    type: 'number',
    visible: true,
  },
  {
    field: 'status',
    name: 'Статус',
    visible: true,
    style: {
      width: '145px',
    },
    renderCell: ({ row }) => (
      <StatusContainer>
        <StatusIndicator
          style={{
            background: row.status?.color,
          }}
        />
        {row.status?.name}
      </StatusContainer>
    ),
  },
  {
    field: 'name',
    name: 'Название',
    visible: true,
    cellStyle: { width: 'auto' },
    style: { width: 'auto' },
    renderCell: ({ row }) => (
      <>
        {row.name.length > 25 ? (
          <Tooltip placement='bottom' title={row.name}>
            <>{row.name.substring(0, 25)}...</>
          </Tooltip>
        ) : (
          row.name
        )}
      </>
    ),
  },
  {
    field: 'templateId',
    name: 'Шаблон',
    visible: true,
    cellStyle: { width: 'auto' },
    style: { width: 'auto' },
  },
  {
    renderCell: ({ row }) => (
      <>{row.templateTypeId ? row.templateTypeName : 'Не задана'}</>
    ),
    name: 'Дисциплина',
    field: 'templateTypeId',
    visible: true,
  },
  {
    renderCell: ({ row }) => (
      <>{moment(row.createdAt).format(DEFAULT_DISPLAY_DATE_FORMAT)}</>
    ),
    name: 'Создан',
    field: 'createdAt',
    visible: true,
  },
  {
    renderCell: ({ row }) =>
      row.updatedAt
        ? moment(row.updatedAt).format(DEFAULT_DISPLAY_DATE_FORMAT)
        : 'Не изменялся',
    name: 'Изменен',
    field: 'updatedAt',
    visible: true,
  },
  {
    field: 'assignees',
    renderCell: ({ row }) => {
      if (!row.assignees.length) {
        return <>Не назначен</>
      }
      const { assignees } = row
      const extraAssigneesCount =
        assignees.length > 1 ? assignees.length - 1 : null

      const firstAssigneeLabel = assignees[0].label

      return (
        <Tooltip
          title={
            extraAssigneesCount ? (
              <TooltipScrollbar>
                {createAssigneesTooltipText(assignees)}
              </TooltipScrollbar>
            ) : (
              ''
            )
          }
          placement='top'
        >
          <NoWrapDiv>
            {firstAssigneeLabel.length > 25 ? (
              <>{firstAssigneeLabel.substring(0, 25)}...</>
            ) : (
              firstAssigneeLabel
            )}
            {extraAssigneesCount && ` +${extraAssigneesCount}`}
          </NoWrapDiv>
        </Tooltip>
      )
    },
    name: 'Ответственный',
    visible: true,
    cellStyle: { width: '145px', overflow: 'hidden' },
    style: { width: '155px' },
  },
  {
    field: 'owner',
    renderCell: ({ row }) => {
      if (!row.owner) {
        return <>Не найден</>
      }

      return <>{row.owner.name}</>
    },
    name: 'Автор',
    visible: true,
    cellStyle: { width: '145px', overflow: 'hidden' },
    style: { width: '155px' },
  },
  { field: 'company', name: 'Компания', visible: true },
  { field: 'signatures', name: 'Подписи', visible: true },
  { field: 'issues', name: 'Замечания', visible: true },
  {
    field: 'progress',
    name: 'Прогресс',
    visible: true,
    renderCell: ({ row }) => (
      <LinearProgressBar colorBar={row.status?.color} value={row.progress} />
    ),
  },
]

type ColumnSettings = {
  field: string
  visible: boolean
}

const getInstanceListColumnOrderFromStorage =
  (): TableColumn<InstanceTableRow>[] => {
    const data = localStorage.getItem('instanceListColumnOrder')
    if (typeof data === 'string') {
      try {
        const parsedData = JSON.parse(data) as ColumnSettings[]

        return columns
          .slice()
          .sort((a, b) => {
            const aIdx = parsedData.findIndex(s => s.field === a.field)
            const bIdx = parsedData.findIndex(s => s.field === b.field)

            // В случае если в localStorage не было настройки колонки (например: в коде добавили новую)
            if (aIdx < 0 || bIdx < 0) {
              return 0
            }

            return aIdx - bIdx
          })
          .map(col => {
            const colFromLocalStorage = parsedData.find(
              ({ field }) => field === col.field,
            )

            return {
              ...col,
              visible:
                colFromLocalStorage === undefined
                  ? true
                  : colFromLocalStorage.visible,
            }
          })
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      }
    }
    return columns
  }

const setColumnsToStorage = (next: TableColumn<InstanceTableRow>[]) => {
  localStorage.setItem(
    'instanceListColumnOrder',
    JSON.stringify(next.map(({ field, visible }) => ({ field, visible }))),
  )
}

export const instancePending$ = combine(
  [
    createInstance.pending$,
    fetchInstanceList.pending$,
    removeInstance.pending$,
    fetchAllowedFiltersPending$,
  ],
  pendings => pendings.some(Boolean),
)

export type ChecklistCtxMenuValue = {
  checklistID?: string
}
