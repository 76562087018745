import { AppliedFilters } from '@gmini/components'
import {
  ASSIGNEES_IL,
  CREATED_DATE_CODE_IL,
  ChecklistListFiltersType,
  ChecklistUrlKeyType,
  OWNER_IDS_IL,
  STATUSES_IL,
  TEMPLATE_TYPE_IDS_IL,
  UPDATED_DATE_CODE_IL,
  appliedDateFilterItemsCodesMap,
  useAppliedFilterAssigneeData,
  useAppliedFilterDateData,
  useAppliedFilterStatusData,
  useAppliedFilterTypeData,
  useAppliedFilterUserData,
} from '@gmini/helpers'
import { useStore } from 'effector-react'
import { useMemo } from 'react'

import { SHORT_DISPLAY_DATE_FORMAT } from '../../../constants'

import { templateTypes$ } from '../../templateTypes.store'
import { userList$ } from '../../user.store'

import { filterService } from '../../checklistFilter.store'
import { instanceStatusList$ } from '../../instanceStatus'

type InstanceListAppliedFiltersProps = {
  onChange?: (filter: ChecklistListFiltersType) => void
}

export const InstanceListAppliedFilters = ({
  onChange,
}: InstanceListAppliedFiltersProps) => {
  const {
    appliedFilters: { useUpdateFilter, appliedFilters$ },
  } = filterService
  const { update } = useUpdateFilter()
  const templateTypes = useStore(templateTypes$)
  const userList = useStore(userList$)
  const instanceStatusList = useStore(instanceStatusList$)
  const filters = useStore(appliedFilters$)

  const preparedTemplateTypes = useAppliedFilterTypeData({
    name: 'Дисциплина',
    urlKey: TEMPLATE_TYPE_IDS_IL,
    typeList: templateTypes,
  })

  const preparedInstanceStatuses = useAppliedFilterStatusData({
    name: 'Статус',
    urlKey: STATUSES_IL,
    statusList: instanceStatusList,
    value: filters.statuses,
  })

  const preparedOwners = useAppliedFilterUserData({
    name: 'Автор',
    urlKey: OWNER_IDS_IL,
    userList,
    value: filters.ownerIds || [],
  })

  const preparedAssignee = useAppliedFilterAssigneeData({
    name: 'Ответственный',
    urlKey: ASSIGNEES_IL,
    value: filters.assignees,
  })

  const preparedUpdatedDate = useAppliedFilterDateData({
    dateFormat: SHORT_DISPLAY_DATE_FORMAT,
    urlKeyCode: UPDATED_DATE_CODE_IL,
    name: 'Изменен',
    value: filters.updatedDateCode ? [filters.updatedDateCode] : [],
    valueName: filters.updatedDateCode
      ? appliedDateFilterItemsCodesMap[filters.updatedDateCode]
      : '',
  })

  const preparedCreatedDate = useAppliedFilterDateData({
    dateFormat: SHORT_DISPLAY_DATE_FORMAT,
    urlKeyCode: CREATED_DATE_CODE_IL,
    name: 'Создан',
    value: filters.createdDateCode ? [filters.createdDateCode] : [],
    valueName: filters.createdDateCode
      ? appliedDateFilterItemsCodesMap[filters.createdDateCode]
      : '',
  })

  const onChangeHandler = (filter: ChecklistUrlKeyType) => {
    const [[key]] = Object.entries(filter)
    update({ [key]: null })
    const filters = appliedFilters$.getState()
    onChange?.(filters)
  }

  const appliedFilters = useMemo(
    () =>
      [
        preparedTemplateTypes,
        preparedInstanceStatuses,
        preparedUpdatedDate,
        preparedCreatedDate,
        preparedOwners,
        preparedAssignee,
      ].filter(({ value }) => value.length),
    [
      preparedTemplateTypes,
      preparedInstanceStatuses,
      preparedUpdatedDate,
      preparedCreatedDate,
      preparedOwners,
      preparedAssignee,
    ],
  )

  return <AppliedFilters onChange={onChangeHandler} items={appliedFilters} />
}
