import styled from 'styled-components'

export const LeftPanelEditPage = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px 0 0 24px;
  z-index: 1;
  position: relative;
  box-shadow: 0px 14px 20px rgba(1, 1, 92, 0.15);
  height: 100%;
`
