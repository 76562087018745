import { ApiCallService } from '@gmini/api-call-service'
import { combine, createEvent, createStore } from 'effector'
import { clone } from 'ramda'

import * as api from '@gmini/chm-api-sdk'

import { ZERO_SEARCH } from '../constants'

type CreateTemplateListServiceProps = {
  fetchTemplateListApi: ApiCallService<
    api.Template.FetchListParams,
    api.Template.Data
  >
  deleteTemplateApi: ApiCallService<
    api.Template.DeleteTemplateParams,
    api.Template.TemplatePopulated
  >
  createTemplateApi: ApiCallService<
    api.Template.CreateTemplateParams,
    api.Template.TemplatePopulated
  >
}

export function createTemplateListService({
  fetchTemplateListApi,
  deleteTemplateApi,
  createTemplateApi,
}: CreateTemplateListServiceProps) {
  const fetchTemplateList = fetchTemplateListApi.createContext()
  const fetchTemplateListPending$ = fetchTemplateList.pending$
  const resetTemplateList = createEvent()
  const deleteTemplate = deleteTemplateApi.createContext()
  const deleteTemplatePending$ = deleteTemplate.pending$
  const createTemplate = createTemplateApi.createContext()
  const createTemplatePending$ = createTemplate.pending$

  type ById = {
    [id: string]: api.Template.Template
  }

  const byId$ = createStore<ById>({}).on(
    fetchTemplateList.doneData,
    (state, result) => {
      const next = clone(state)

      result.list.forEach(
        (template, idx) => (next[template.id] = { ...template }),
      )
      return next
    },
  )

  type IdsBySearchValue = Record<string | symbol, number[] | undefined>

  const ids$ = createStore<IdsBySearchValue>({})
    .on(fetchTemplateList.done, (state, { params, result }) => {
      const next = { ...state }
      const search = params.filter || ZERO_SEARCH

      const prevIds = state[search] || []
      const resultIds = result.list.map(({ id }) => id)
      const nextIds = prevIds
        .filter(prevId => resultIds.every(id => id !== prevId))
        .concat(...resultIds)

      next[search] = nextIds
      return next
    })
    .on(resetTemplateList, state => ({}))
    .on(deleteTemplate.doneData, (state, { id }) => {
      const next = { ...state }

      Object.entries(state).forEach(([key, ids]) => {
        const nextIds = ids?.filter(item => item !== id) || []

        if (ids?.length !== nextIds.length) {
          next[key] = nextIds
        }
      })

      return next
    })

  const totalTemplates$ = createStore<number | null>(null).on(
    fetchTemplateList.done,
    (state, { result }) => result.total,
  )

  return {
    templateList$: combine({ byId$, ids$, totalTemplates$ }),
    fetchTemplateList,
    fetchTemplateListPending$,
    resetTemplateList,
    deleteTemplate,
    deleteTemplatePending$,
    createTemplate,
    createTemplatePending$,
  }
}
