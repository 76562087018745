import { createStore } from 'effector'

import * as api from '@gmini/chm-api-sdk'

import { clone } from 'ramda'
import {
  defaultInstanceStatusColor,
  instanceStatusColorMap,
} from '@gmini/helpers'

export const fetchStatusList = api.InstanceStatus.fetchList.createContext()
export const fetchStatusListPending$ = fetchStatusList.pending$

export type PreparedInstanceStatus = api.InstanceStatus.Data & { color: string }

export const instanceStatusList$ = createStore<PreparedInstanceStatus[]>([]).on(
  fetchStatusList.doneData,
  (state, result) =>
    result.map(item => ({
      ...item,
      color: instanceStatusColorMap[item.status] || defaultInstanceStatusColor,
    })),
)

export const instanceStatusListByStatus$ = createStore<
  Record<string, PreparedInstanceStatus>
>({}).on(instanceStatusList$.updates, (state, result) => {
  const next = clone(state)

  result.forEach(item => (next[item.status] = item))

  return next
})
