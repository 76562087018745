import {
  // AllFolders, //TODO временно скрыто в рамках задачи https://gmini.atlassian.net/browse/CI-34
  Tabs,
} from '@gmini/ui-kit'
import { useHistory } from 'react-router'

import * as smApi from '@gmini/sm-api-sdk'
import qs from 'query-string'
import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  PageContentWrapper,
  // SwitchProjectPopoverItemSeparator,  //TODO временно скрыто в рамках задачи https://gmini.atlassian.net/browse/CI-34
  // SwitchProjectPopoverItemCount,
  // SwitchProjectProjectName,
  SwitchProjectSelectWrapper,
} from '@gmini/components'

import { SwitchProject, SwitchProjectItem } from '@gmini/common'

import { useStore } from 'effector-react'

import { PROJECT_URN, SELECTED_TAB, queryParser } from '@gmini/helpers'

import {
  ChecklistManagerWrapper,
  InstanceList,
  TemplateList,
} from '../../organisms'

import { TabIndex } from '../../../constants'

import { TabTitle } from './ChecklistManagerPage.styled'
import {
  fetchProjectList,
  fetchProjectListPending$,
  filterService,
  projectList$,
} from './model'

export const ChecklistManagerPage = () => {
  const history = useHistory()

  const projectUrn = queryParser({ key: PROJECT_URN }) as string
  const selectedTab =
    (queryParser({
      key: SELECTED_TAB,
      options: { parseNumbers: true },
    }) as number) || TabIndex.InstanceList
  const [openedSwitchProjectPopup, setOpenedSwitchProjectPopup] =
    useState(false)
  const fetchProjectListPending = useStore(fetchProjectListPending$)
  const projectList = useStore(projectList$)
  const selectedProject = useMemo(
    () => projectList.find(({ urn }) => projectUrn === urn) || null,
    [projectList, projectUrn],
  )

  const onChangeTab = (nextTabIdx: number) => {
    const prev = qs.parse(window.location.search)
    const search = qs.stringify({
      ...prev,
      [SELECTED_TAB]: nextTabIdx.toString(),
    })
    history.push({ search })
  }
  const {
    appliedFilters: { setFilterValuesFromUrl },
  } = filterService[selectedTab]

  const queries = qs.parse(window.location.search)

  useEffect(() => {
    setFilterValuesFromUrl(queries)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchProjectList.submit()
  }, [])

  const onChangeProject = useCallback(
    (project: smApi.Project) => {
      const prev = qs.parse(window.location.search)
      const search = qs.stringify({ ...prev, [PROJECT_URN]: project.urn })
      history.push({ search })
    },
    [history],
  )

  useEffect(() => {
    if (!projectUrn && projectList.length) {
      onChangeProject(projectList[0])
    }
  }, [onChangeProject, projectList, projectUrn])

  const onCloseSwitchProjectPopup = () => setOpenedSwitchProjectPopup(false)

  return (
    <ChecklistManagerWrapper
      selectedProject={selectedProject}
      brandSecondary={
        <SwitchProjectSelectWrapper>
          <SwitchProject
            projectList={projectList}
            disabled={fetchProjectListPending}
            loading={fetchProjectListPending}
            onChangeProject={onChangeProject}
            selectedProjectName={selectedProject?.name || ''}
            onToggle={() => setOpenedSwitchProjectPopup(prev => !prev)}
            opened={openedSwitchProjectPopup}
            onClose={onCloseSwitchProjectPopup}
            // badgeIcon={!selectedProject && <AllFolders />} //TODO временно скрыто в рамках задачи https://gmini.atlassian.net/browse/CI-34
            renderItem={(project: smApi.Project, idx: number) => {
              const SwitchProjectItemJsx = (
                <SwitchProjectItem
                  onChangeProject={onChangeProject}
                  onClose={onCloseSwitchProjectPopup}
                  project={project}
                />
              )
              // if (idx === 0) { //TODO временно скрыто в рамках задачи https://gmini.atlassian.net/browse/CI-34
              //   return (
              //     <>
              //       <SwitchProjectPopoverItem
              //         onClick={() => {
              //           onChangeProject(null)
              //           onCloseSwitchProjectPopup()
              //         }}
              //       >
              //         <SwitchProjectProjectName>
              //           {allProjectItemName}
              //           <SwitchProjectPopoverItemCount>
              //             {projectList.length}
              //           </SwitchProjectPopoverItemCount>
              //         </SwitchProjectProjectName>
              //       </SwitchProjectPopoverItem>
              //       <SwitchProjectPopoverItemSeparator />
              //       {SwitchProjectItemJsx}
              //     </>
              //   )
              // }

              return SwitchProjectItemJsx
            }}
          />
        </SwitchProjectSelectWrapper>
      }
    >
      <PageContentWrapper>
        <Tabs
          onChangeTab={onChangeTab}
          activeTabIndex={selectedTab}
          headerStyles={{
            fontSize: '14px',
            height: '49px',
            gap: 19,
          }}
          contentStyles={{
            height: '100%',
            paddingTop: '0',
            display: 'flex',
            minHeight: 0,
          }}
          tabs={[
            {
              title: <TabTitle>Чек-листы</TabTitle>,
              content: <InstanceList projectList={projectList} />,
            },
            {
              title: <TabTitle>Шаблоны</TabTitle>,
              content: <TemplateList />,
            },
          ]}
        />
      </PageContentWrapper>
    </ChecklistManagerWrapper>
  )
}

// const allProjectItemName = 'Все проекты' //TODO временно скрыто в рамках задачи https://gmini.atlassian.net/browse/CI-34
