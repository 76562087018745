import styled from 'styled-components'
import { Button } from '@gmini/ui-kit'

import { Title } from '@gmini/components'

type ContainerProps = {
  collapsed?: boolean
}

export const Container = styled.div`
  width: ${(props: ContainerProps) => (props.collapsed ? '56px' : '260px')};
  min-width: ${(props: ContainerProps) => (props.collapsed ? '56px' : '260px')};
  padding: ${(props: ContainerProps) => (props.collapsed ? '0 10px' : '0')};
  position: relative;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 30px;

  ${Title} {
    white-space: nowrap;
  }
`

export const SchemeTitle = styled(Title)`
  top: 72px;
`

export const Count = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  margin: auto 0 auto auto;
`

type SchemeItemProps = { selected?: boolean }

export const SchemeItem = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  display: flex;
  gap: 8px;
  padding: 6px;
  color: ${(props: SchemeItemProps) =>
    props.selected ? '#FFFFFF' : '#353B60'};
  border-radius: 4px;
  cursor: pointer;
  background: ${(props: SchemeItemProps) =>
    props.selected ? '#4C5ECF' : 'inherit'};
  white-space: nowrap;

  & path {
    fill: ${(props: SchemeItemProps) =>
      props.selected ? 'rgba(255, 255, 255, 0.5)' : 'rgba(53, 59, 96, 0.5)'};
  }

  & svg {
    min-width: 24px;
  }

  &:hover {
    background: ${(props: SchemeItemProps) =>
      props.selected ? '#4C5ECF' : 'rgba(0, 5, 100, 0.04)'};
  }

  & ${Count} {
    color: ${(props: SchemeItemProps) =>
      props.selected ? 'rgba(255, 255, 255, 0.5)' : 'rgba(53, 59, 96, 0.5)'};
  }
`

export const SchemeItemText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  margin: auto 0;
  display: flex;
`

export const SectionList = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const AddButton = styled(Button)`
  margin-top: 8px;
  margin-bottom: 12px;
`

export const ExternalSection = styled.div`
  display: flex;
  gap: 8px;
  padding: 6px 8px 6px 6px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #353b60;
  letter-spacing: 0.1px;
  opacity: 0.2;
  border-top: 1px solid #e5e7f1;
  padding-top: 15px;
`
