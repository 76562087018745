import styled from 'styled-components'
import { DialogContent } from '@gmini/ui-kit/lib/DialogContent'
import { IconButton } from '@gmini/ui-kit'

export const Content = styled(DialogContent)`
  height: 600px;
  width: 1280px;
  overflow-y: hidden;
  padding-bottom: 16px;
  display: flex;
  gap: 35px;
  margin-top: 20px;
`

export const CloseButton = styled(IconButton)`
  && {
    margin-left: auto;

    & path {
      fill: #353b60;
    }
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-right: 24px;
`
