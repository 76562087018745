import * as api from '@gmini/chm-api-sdk'

import { createSection } from '../../sectionCRUD.store'

export const createTemplate = api.Template.createTemplate.createContext()

createTemplate.doneData.watch(({ id, version }) => {
  createSection.submit({
    templateId: id,
    templateVersion: version,
    section: {
      description: '',
      name: 'Новая секция',
      weight: 0,
    },
  })
})
