import { useStore } from 'effector-react'
import { useEffect, useState } from 'react'

import * as api from '@gmini/chm-api-sdk'

//TODO переделать на сервис и вынести в helpers
const fetchMostRecentTemplate =
  api.Template.fetchTemplateMostRecent.createContext()
const fetchMostRecentTemplatePending$ = fetchMostRecentTemplate.pending$

export const useTemplatePopulated = () => {
  const [templatePopulated, setTemplatePopulated] =
    useState<api.Template.TemplatePopulated | null>(null)

  const fetchTemplatePopulatedPending = useStore(
    fetchMostRecentTemplatePending$,
  )

  useEffect(() => {
    const fetchMostRecentTemplateSubscription =
      fetchMostRecentTemplate.doneData.watch(templatePopulated => {
        setTemplatePopulated(templatePopulated)
      })

    return () => fetchMostRecentTemplateSubscription.unsubscribe()
  }, [])

  return {
    fetchTemplatePopulatedPending,
    templatePopulated,
    fetchMostRecentTemplate,
  }
}
