import { useMemo } from 'react'

import { queryParser } from '../filterService'

type UseAppliedFilterTypeData<T> = {
  urlKey: string
  name: string
  typeList: T[]
}

export const useAppliedFilterTypeData = <
  T extends { id: number; name: string },
>({
  urlKey,
  name,
  typeList,
}: UseAppliedFilterTypeData<T>) => {
  const issueTypesFromUrl = (
    queryParser({
      key: urlKey,
    }) as string
  )
    ?.split(',')
    .map(Number)

  return useMemo(
    () => ({
      name,
      value: typeList
        .filter(item =>
          issueTypesFromUrl?.some(issueType => issueType === item.id),
        )
        .map(item => item.name),
      urlKey,
    }),
    [issueTypesFromUrl, name, typeList, urlKey],
  )
}
