import { createUsersService } from '@gmini/helpers'

export const {
  fetchUserList,
  fetchUserListPending$,
  useFilteredUserList,
  useUserList,
  userList$,
} = createUsersService()

export const {
  fetchUserList: fetchAllUserList,
  fetchUserListPending$: fetchAllUserListPending$,
  useFilteredUserList: useFilteredAllUserList,
  useUserList: useAllUserList,
  userList$: allUserList$,
} = createUsersService()
