import styled from 'styled-components'

import { BackButtonProps } from './BackButton.types'

export const BackButtonStyled = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  width: fit-content;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: 0.2s ease-out;
  color: #00003d;
  cursor: ${(props: BackButtonProps) =>
    props.disabled ? 'default' : 'pointer'};
  ${(props: BackButtonProps) => props.active && 'color: #2235aa;'}
  ${(props: BackButtonProps) =>
    props.disabled && 'color: rgba(53, 59, 96, 0.2) !important;'}

  & path {
    fill: #00003d;
    transition: 0.2s ease-out;
    ${(props: BackButtonProps) => props.active && 'fill: #2235aa;'}
    ${(props: BackButtonProps) =>
      props.disabled && 'fill: rgba(53, 59, 96, 0.2) !important;'}
  }

  &:hover {
    color: #4c5ecf;

    & path {
      fill: #4c5ecf;
    }
  }

  &:active {
    color: #2235aa;

    & path {
      fill: #2235aa;
    }
  }

  &:disabled {
    color: rgba(53, 59, 96, 0.2);

    & path {
      fill: rgba(53, 59, 96, 0.2);
    }
  }
`
