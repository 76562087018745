import { createEffect, createEvent, createStore } from 'effector'

import * as ismApi from '@gmini/ism-api-sdk'

import { clone } from 'ramda'

import qs, { ParsedQuery } from 'query-string'

import { useHistory } from 'react-router-dom'

import { assertNever } from '@gmini/utils'

import { useCallback } from 'react'

import {
  ASSIGNEES_IL,
  CREATED_DATE_CODE_IL,
  CREATED_DATE_RANGE_IL,
  DEADLINE_CODE_IL,
  OWNER_IDS_IL,
  RESET_ALL,
  SEARCH_IL,
  STATUSES_IL,
  UPDATED_DATE_CODE_IL,
  UPDATED_DATE_RANGE_IL,
  checklistTemplateAvailableFilters,
} from '../constants'
import { MapQueryBy } from '../enum'

import { AssigneeListItem } from '../../createAssigneeGroupListService'

import {
  ChecklistListFiltersQueryType,
  ChecklistListFiltersType,
  ChecklistQueryType,
  ChecklistUpdateType,
  ChecklistUrlKeyType,
} from '../types/checklist.types'
import { ValueOf } from '../types/types'

const initState: ChecklistListFiltersQueryType = {
  searchIL: '',
  statusesIL: [],
  ownerIdsIL: [],
  assigneesIL: [],
  createdDateRangeIL: [],
  updatedDateRangeIL: [],
  createdDateCodeIL: null,
  updatedDateCodeIL: null,
}

export const urlKeyMap: Record<
  keyof ChecklistListFiltersQueryType,
  ValueOf<typeof checklistTemplateAvailableFilters>
> = {
  [SEARCH_IL]: 'filter',
  [STATUSES_IL]: 'statuses',
  [OWNER_IDS_IL]: 'ownerIds',
  [ASSIGNEES_IL]: 'assignees',
  [CREATED_DATE_RANGE_IL]: 'createdDateRange',
  [UPDATED_DATE_RANGE_IL]: 'updatedDateRange',
  [CREATED_DATE_CODE_IL]: 'createdDateCode',
  [UPDATED_DATE_CODE_IL]: 'updatedDateCode',
} as const
export const appliedFilters = () => {
  const parse = <Key extends keyof ChecklistListFiltersQueryType>(
    key: Key,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any => {
    switch (key) {
      case ASSIGNEES_IL: {
        try {
          return JSON.parse(value)
        } catch (error) {
          //TODO сбрасывать фильтры в урле, json.parse упал
          throw new Error(JSON.stringify(error))
        }
      }

      case STATUSES_IL:
      case OWNER_IDS_IL:
        return value.split(',')

      case SEARCH_IL:
      case CREATED_DATE_RANGE_IL:
      case UPDATED_DATE_RANGE_IL:
      case CREATED_DATE_CODE_IL:
      case UPDATED_DATE_CODE_IL:
        return value
      default:
        return assertNever('Not expected filter key from url', key)
    }
  }

  const updateArrayTypeValue = ({ state, key, value }: ChecklistUpdateType) => {
    if (!value) {
      return { ...state, [key]: [] }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentValue = Array.isArray((state as any)[key])
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((state as any)[key] as Array<string>)
      : []

    const isExist = !!currentValue.filter((el: string) => el === value).length
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(state as any)[key] = isExist
      ? currentValue.filter(el => el !== value)
      : [...currentValue, value]

    return state
  }

  const updateObjectTypeValue = ({
    state,
    key,
    value,
  }: ChecklistUpdateType) => {
    //TODO под каждый тип фильра тут свой метод и свой входящий тип
    if (!value) {
      return { ...state, [key]: [] }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentValue = Array.isArray((state as any)[key])
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((state as any)[key] as Array<AssigneeListItem>)
      : []

    const isExist = !!currentValue.filter(
      (el: AssigneeListItem) =>
        `${el.source}_${el.assigneeId}` ===
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        `${(value as any)?.source}_${(value as any)?.assigneeId}`,
    ).length
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(state as any)[key] = isExist
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state as any)[key].filter(
          (el: AssigneeListItem) =>
            `${(el as AssigneeListItem).source}_${
              (el as AssigneeListItem).assigneeId
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }` !== `${(value as any).source}_${(value as any).assigneeId}`,
        )
      : [...currentValue, value]

    return state
  }

  const updateStringTypeValue = ({
    state,
    key,
    value,
  }: ChecklistUpdateType) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(state as any)[key] = value

    return state
  }

  const insert = ({
    state,
    key,
    value,
  }: {
    state: ChecklistListFiltersQueryType
    key: keyof ChecklistUrlKeyType
    value: string | null
  }) => {
    switch (key) {
      case SEARCH_IL:
        return updateStringTypeValue({
          state,
          key,
          value,
        })
      case STATUSES_IL:
        return updateArrayTypeValue({
          state,
          key,
          value,
        })

      case UPDATED_DATE_CODE_IL: {
        return updateStringTypeValue({
          state,
          key,
          value,
        })
      }
      case CREATED_DATE_CODE_IL: {
        return updateStringTypeValue({
          state,
          key,
          value,
        })
      }
      case DEADLINE_CODE_IL: {
        return updateStringTypeValue({
          state,
          key,
          value,
        })
      }
      case OWNER_IDS_IL: {
        return updateArrayTypeValue({
          state,
          key,
          value,
        })
      }
      case ASSIGNEES_IL: {
        return updateObjectTypeValue({
          state,
          key,
          value,
        })
      }
      case RESET_ALL: {
        return initState
      }
      default:
        return state
    }
  }

  const setFilterValuesFromUrl = createEffect((query: ParsedQuery) =>
    Object.entries(query).reduce(
      (acc: ChecklistListFiltersQueryType, [k, v]) => {
        if (
          Object.prototype.hasOwnProperty.call(acc, k) &&
          typeof v === 'string'
        ) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ;(acc as any)[k] = parse(k as keyof ChecklistListFiltersQueryType, v)
        }

        return acc
      },
      clone(initState),
    ),
  )
  const updateFilter = createEvent<ChecklistQueryType>()
  const useUpdateFilter = () => {
    const history = useHistory()

    const update = useCallback(
      (filter: ChecklistQueryType) => {
        updateFilter(filter)
        const prev = qs.parse(window.location.search)
        const filters = filtersByQueryKey$.getState()
        const queryParams = qs.stringify(
          {
            ...prev,
            ...filters,
            assigneesIL: filters.assigneesIL.length
              ? JSON.stringify(filters.assigneesIL)
              : [],
          },
          {
            arrayFormat: 'separator',
            arrayFormatSeparator: ',',
            skipNull: true,
            skipEmptyString: true,
          },
        )
        history.push({ search: queryParams })
      },
      [history],
    )

    return { update }
  }
  const applyFilters = createEvent<ismApi.Filters.IssueFilterType>()

  const filtersByQueryKey$ = createStore<ChecklistListFiltersQueryType>(
    initState,
  )
    .on(setFilterValuesFromUrl.doneData, (state, newFilters) => ({
      ...state,
      ...newFilters,
    }))
    .on(updateFilter, (state, filters) => {
      let newSate = clone(state)
      //TODO поддержать кейс, когда в updateFilter закинули несколько свойств
      const [[key, value]] = Object.entries(filters)
      if (newSate) {
        newSate = insert({
          state: newSate,
          key: key as keyof ChecklistUrlKeyType,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value: value as any,
        })
      }

      return newSate
    })

  const appliedFilters$ = filtersByQueryKey$.map(filters =>
    Object.entries(filters).reduce((acc: ChecklistListFiltersType, [k, v]) => {
      const key = urlKeyMap[k as keyof ChecklistListFiltersQueryType]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(acc as any)[key as string] = v

      return acc
    }, {} as ChecklistListFiltersType),
  )

  const mapQueryParams = ({
    by,
    params,
  }: {
    by: MapQueryBy
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: Record<string, any>
  }) => {
    const a = Object.entries(urlKeyMap).reduce((acc, [k, v]) => {
      const key = by === MapQueryBy.UrlKey ? k : v
      const paramsKey = by === MapQueryBy.UrlKey ? v : k
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value = (params as any)[paramsKey as string]
      if (value) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(acc as any)[key as string] = value
      }

      return acc
    }, {})
    return a
  }

  return {
    // Проверить публичные api
    setFilterValuesFromUrl,
    updateFilter,
    useUpdateFilter,
    appliedFilters$,
    applyFilters,
    mapQueryParams,
  }
}
