import styled from 'styled-components'

import { Title } from '@gmini/components'

type DetailsContainerProps = {
  collapsed?: boolean
}

export const DetailsContainer = styled.div`
  padding: ${(props: DetailsContainerProps) =>
    props.collapsed ? '24px 10px' : '24px'};
  background: linear-gradient(
      0deg,
      rgba(244, 244, 248, 0.5),
      rgba(244, 244, 248, 0.5)
    ),
    #ffffff;
  position: relative;
  min-height: 100%;
`

export const DetailsTitle = styled(Title)`
  top: 100px;
`

export type DetailsHeaderProps = {
  collapsed?: boolean
}

export const DetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 19px;

  ${Title} {
    white-space: nowrap;
  }
`

export const DetailsFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 100%;
  padding-top: 26px;
`

export const DetailsField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const DetailsFieldLabel = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #000335;
`

export const DetailsWrapper = styled.div`
  width: ${(props: DetailsContainerProps) =>
    props.collapsed ? '56px' : '400px'};
  min-width: ${(props: DetailsContainerProps) =>
    props.collapsed ? '56px' : '400px'};
`
