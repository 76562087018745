import styled from 'styled-components'

type WrapperProps = {
  disabled?: boolean
}

type InactiveProps = {
  disabled?: boolean
}

export const Inactive = styled.div`
  border: 2px solid rgba(53, 59, 96, 0.5);
  width: 20px;
  height: 20px;
  padding: 2px;
  border-radius: 50%;
  ${(props: InactiveProps) =>
    props.disabled &&
    'border: 2px solid rgba(53, 59, 96, 0.2); pointer-events: none;'}

  &:hover {
    ${(props: InactiveProps) => !props.disabled && 'border: 2px solid #353b60;'}
  }

  &:active {
    ${(props: InactiveProps) => !props.disabled && 'border: 2px solid #353b60;'}
  }
`

export const InactiveWrapper = styled.div`
  padding: 2px;
`

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;

  ${(props: WrapperProps) => props.disabled && 'pointer-events: none;'}
`

export const Label = styled.div`
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000335;
  white-space: nowrap;
`
