import { InstanceStatus } from '@gmini/chm-api-sdk'

import { useStoreMap } from 'effector-react'

import { instanceStatusListByStatus$ } from '../../instanceStatus'

import {
  InstanceStatusRoot,
  InstanceStatusText,
} from './InstanceStatusRender.styled'

export type InstanceStatusRenderProps = {
  status: InstanceStatus.Enum
}

export const InstanceStatusRender = ({ status }: InstanceStatusRenderProps) => {
  const preparedStatus = useStoreMap({
    store: instanceStatusListByStatus$,
    keys: [status],
    fn: (instanceStatusListByStatus, [status]) =>
      instanceStatusListByStatus[status]
        ? instanceStatusListByStatus[status]
        : null,
  })

  if (!preparedStatus) {
    return null
  }

  return (
    <>
      <InstanceStatusRoot bg={preparedStatus.color}>
        <InstanceStatusText>{preparedStatus.name}</InstanceStatusText>{' '}
      </InstanceStatusRoot>
    </>
  )
}
