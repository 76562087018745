import styled from 'styled-components'

export const SwitchSectionBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  width: 800px;
  margin: 0;
  margin-bottom: 40px;
`
