import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { ClickAwayListener } from '@mui/material'

import {
  HorizontalThreeDots,
  IconButton,
  Button,
  PlusCircleFill,
  Trash,
  TextArea,
  Select,
  RadioButton,
} from '@gmini/ui-kit'

import {
  DropdownMenu,
  FieldLabel,
  TextFieldWithPreview,
} from '@gmini/components'

import * as api from '@gmini/chm-api-sdk'

import { QuestionForm } from '../QuestionForm'

import { deleteSection, updateSection } from '../../sectionCRUD.store'
import { createQuestion } from '../../questionCRUD.store'
import { fetchMostRecentTemplate } from '../../pages/EditTemplatePage/EditTemplate.store'

import {
  Container,
  Header,
  RightSideHeader,
  QuestionCount,
  FormLine,
  FieldContainer,
  FieldRightSide,
  Footer,
  SelectRenderValue,
} from './SectionForm.styled'

type SectionFormProps = {
  section: api.Template.Section
  templateVersion: number
  templateId: number
  sectionIdx: number
  disabled?: boolean
  onToggleEditing: (value: boolean) => void
  editing: boolean
  setSelectedSectionIdx: Dispatch<SetStateAction<number>>
}

export const SectionForm = ({
  section,
  templateVersion,
  templateId,
  sectionIdx,
  disabled,
  onToggleEditing,
  editing,
  setSelectedSectionIdx,
}: SectionFormProps) => {
  const [descriptionSection, setDescriptionSection] = useState(
    section.description,
  )
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(null)
  const [delayedCreateQuestion, setDelayedCreateQuestion] = useState(false)

  const [anchorDropdownMenu, setAnchorElDropdownMenu] =
    useState<HTMLButtonElement | null>(null)

  useEffect(() => {
    const createQuestionSubscription = fetchMostRecentTemplate.doneData.watch(
      ({ version }) => {
        if (version && delayedCreateQuestion) {
          createQuestion({
            question: {
              name: '',
              weight: 0,
              description: '',
            },
            templateVersion: version,
            templateId,
            sectionId: section.id,
          })
          setDelayedCreateQuestion(false)
        }
      },
    )

    return () => {
      createQuestionSubscription.unsubscribe()
    }
  }, [delayedCreateQuestion, section.id, templateId, templateVersion])

  const handleCloseDropdownMenu = () => {
    setAnchorElDropdownMenu(null)
  }

  const onUpdateSection = useCallback(
    (fieldName: string, value: string) => {
      updateSection({
        templateVersion,
        templateId,
        sectionId: section.id,
        section: { ...section, [fieldName]: value },
      })
    },
    [section, templateId, templateVersion],
  )

  const onChangeSectionName = (value: string) => {
    onUpdateSection('name', value)
  }

  const onCreateQuestion = () => {
    if (editing) {
      setDelayedCreateQuestion(true)
      return
    }
    createQuestion({
      question: { name: '', weight: 0, description: '' },
      templateVersion,
      templateId,
      sectionId: section.id,
    })
  }

  const onDelete = () => {
    deleteSection({ templateVersion, templateId, sectionId: section.id })
    setSelectedSectionIdx(prevIdx => (prevIdx - 1 >= 0 ? prevIdx - 1 : 0))
  }

  return (
    <Container>
      <DropdownMenu
        anchorEl={anchorDropdownMenu}
        handleClose={handleCloseDropdownMenu}
        items={[
          {
            name: 'Удалить',
            onClick: onDelete,
            icon: <Trash color='#353B60' />,
          },
        ]}
      />
      <Header>
        <TextFieldWithPreview
          value={section.name}
          onChange={onChangeSectionName}
          renderValue={(value: string) => `${sectionIdx + 1}. ${value}`}
          disabled={disabled}
          visibleEditIcon
        />
        <RightSideHeader>
          <QuestionCount>Вопросов: {section?.items?.length}</QuestionCount>
          <IconButton
            size='regular'
            onClick={event => setAnchorElDropdownMenu(event.currentTarget)}
            active={Boolean(anchorDropdownMenu)}
            disabled={disabled}
          >
            <HorizontalThreeDots />
          </IconButton>
        </RightSideHeader>
      </Header>

      <FormLine>
        <FieldContainer>
          <FieldLabel>Описание</FieldLabel>
          <TextArea
            placeholder='Добавить описание'
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
              setDescriptionSection(event.target.value)
            }
            value={descriptionSection}
            onBlur={() => {
              if (descriptionSection !== section.description) {
                onUpdateSection('description', descriptionSection)
              } else {
                onToggleEditing(false)
              }
            }}
            disabled={disabled}
            onFocus={() => {
              onToggleEditing(true)
            }}
          />
        </FieldContainer>

        <FieldRightSide>
          <FieldLabel>Вес секции</FieldLabel>
          <Select
            options={[]}
            // eslint-disable-next-line no-empty-function
            onChange={() => {}}
            value={{}}
            getOptionLabel={() => ''}
            renderSelectValue={() => `${section.weight}%`}
            colorScheme='white'
            disabled
            emptyOptionListMessage='Нет доступных совпадений'
          />
        </FieldRightSide>
      </FormLine>

      <FormLine>
        <FieldContainer>
          <FieldLabel>Тип ответа</FieldLabel>
          <Select
            options={[]}
            // eslint-disable-next-line no-empty-function
            onChange={() => {}}
            value={{}}
            getOptionLabel={() => ''}
            colorScheme='white'
            renderSelectValue={() => (
              <SelectRenderValue>
                <RadioButton selected />
                Принято / Не принято
              </SelectRenderValue>
            )}
            disabled
            emptyOptionListMessage='Нет доступных совпадений'
          />
        </FieldContainer>
      </FormLine>
      <ClickAwayListener onClickAway={() => setSelectedQuestion(null)}>
        <div>
          {section?.items?.map((question, idx) => (
            <QuestionForm
              // eslint-disable-next-line react/no-array-index-key
              key={question.id}
              question={question}
              idxSection={1}
              sectionId={section.id}
              idxQuestion={idx}
              selected={selectedQuestion === idx}
              onClick={() => setSelectedQuestion(idx)}
              templateId={templateId}
              templateVersion={templateVersion}
              disabled={disabled}
              onToggleEditing={onToggleEditing}
            />
          ))}
        </div>
      </ClickAwayListener>

      <Footer>
        <Button
          size='small'
          color='tertiary'
          onClick={onCreateQuestion}
          leftIcon={<PlusCircleFill />}
        >
          Добавить вопрос
        </Button>
      </Footer>
    </Container>
  )
}
