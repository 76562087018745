import { createEffect, createEvent, createStore } from 'effector'

import { clone } from 'ramda'

import qs, { ParsedQuery } from 'query-string'

import { useHistory } from 'react-router-dom'

import { assertNever } from '@gmini/utils'

import { useCallback } from 'react'

import { AssigneeListItem } from '../../createAssigneeGroupListService'

import {
  ASSIGNEES_TL,
  ATTRIBUTES_TL,
  AUTHOR_IDS_TL,
  CREATED_DATE_CODE_TL,
  CREATED_DATE_RANGE_TL,
  RESET_ALL,
  SEARCH_TL,
  SORT_BY_FIELD_NAME_TL,
  SORT_BY_OPERATOR_TL,
  UPDATED_DATE_CODE_TL,
  UPDATED_DATE_RANGE_TL,
  templateAvailableFilters,
} from '../constants'
import {
  IssueTemplateListFiltersQueryType,
  IssueTemplateListFiltersType,
  IssueTemplateQueryType,
  IssueTemplateUpdateType,
  IssueTemplateUrlKeyType,
} from '../types/issueTemplate.types'
import { ValueOf } from '../types/types'

const initState: IssueTemplateListFiltersQueryType = {
  searchTL: '',
  attributeValueIdsTL: [],
  authorIdsTL: [],
  assigneesTL: [],
  createdDateRangeTL: [],
  updatedDateRangeTL: [],
  createdDateCodeTL: null,
  updatedDateCodeTL: null,
  sortByOperatorTL: null,
  sortByFieldNameTL: null,
}

export const urlKeyMap: Record<
  keyof IssueTemplateListFiltersQueryType,
  ValueOf<typeof templateAvailableFilters>
> = {
  [SEARCH_TL]: 'filter',
  [ATTRIBUTES_TL]: 'attributeValueIds',
  [AUTHOR_IDS_TL]: 'authorIds',
  [ASSIGNEES_TL]: 'assignees',
  [CREATED_DATE_RANGE_TL]: 'createdDateRange',
  [UPDATED_DATE_RANGE_TL]: 'updatedDateRange',
  [CREATED_DATE_CODE_TL]: 'createdDateCode',
  [UPDATED_DATE_CODE_TL]: 'updatedDateCode',
  [SORT_BY_OPERATOR_TL]: 'sortByOperator',
  [SORT_BY_FIELD_NAME_TL]: 'sortByFieldName',
} as const
export const appliedFilters = () => {
  const parse = <Key extends keyof IssueTemplateListFiltersQueryType>(
    key: Key,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any => {
    switch (key) {
      case ASSIGNEES_TL: {
        try {
          return JSON.parse(value)
        } catch (error) {
          //TODO сбрасывать фильтры в урле, json.parse упал
          throw new Error(JSON.stringify(error))
        }
      }
      case AUTHOR_IDS_TL:
        return value.split(',')

      case ATTRIBUTES_TL:
        return value.split(',').map(Number)

      case SEARCH_TL:
      case CREATED_DATE_RANGE_TL:
      case UPDATED_DATE_RANGE_TL:
      case CREATED_DATE_CODE_TL:
      case UPDATED_DATE_CODE_TL:
      case SORT_BY_OPERATOR_TL:
      case SORT_BY_FIELD_NAME_TL:
        return value
      default:
        return assertNever('Not expected filter key from url', key)
    }
  }

  const updateArrayTypeValue = ({
    state,
    key,
    value,
  }: IssueTemplateUpdateType) => {
    if (!value) {
      return { ...state, [key]: [] }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentValue = Array.isArray((state as any)[key])
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((state as any)[key] as Array<string>)
      : []

    const isExist = !!currentValue.filter((el: string) => el === value).length
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(state as any)[key] = isExist
      ? currentValue.filter(el => el !== value)
      : [...currentValue, value]

    return state
  }

  const updateObjectTypeValue = ({
    state,
    key,
    value,
  }: IssueTemplateUpdateType) => {
    //TODO под каждый тип фильра тут свой метод и свой входящий тип
    if (!value) {
      return { ...state, [key]: [] }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentValue = Array.isArray((state as any)[key])
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((state as any)[key] as Array<AssigneeListItem>)
      : []

    const isExist = !!currentValue.filter(
      (el: AssigneeListItem) =>
        `${el.source}_${el.assigneeId}` ===
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        `${(value as any)?.source}_${(value as any)?.assigneeId}`,
    ).length
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(state as any)[key] = isExist
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state as any)[key].filter(
          (el: AssigneeListItem) =>
            `${(el as AssigneeListItem).source}_${
              (el as AssigneeListItem).assigneeId
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }` !== `${(value as any).source}_${(value as any).assigneeId}`,
        )
      : [...currentValue, value]

    return state
  }

  const updateStringTypeValue = ({
    state,
    key,
    value,
  }: IssueTemplateUpdateType) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(state as any)[key] = value

    return state
  }

  const insert = ({
    state,
    key,
    value,
  }: {
    state: IssueTemplateListFiltersQueryType
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    key: any
    value: string | null
  }) => {
    switch (key) {
      case SEARCH_TL:
        return updateStringTypeValue({
          state,
          key,
          value,
        })
      case ATTRIBUTES_TL:
        return updateArrayTypeValue({
          state,
          key,
          value,
        })

      case UPDATED_DATE_CODE_TL: {
        return updateStringTypeValue({
          state,
          key,
          value,
        })
      }
      case CREATED_DATE_CODE_TL: {
        return updateStringTypeValue({
          state,
          key,
          value,
        })
      }
      case AUTHOR_IDS_TL: {
        return updateArrayTypeValue({
          state,
          key,
          value,
        })
      }
      case ASSIGNEES_TL: {
        return updateObjectTypeValue({
          state,
          key,
          value,
        })
      }
      case SORT_BY_OPERATOR_TL: {
        return updateStringTypeValue({
          state,
          key,
          value,
        })
      }
      case SORT_BY_FIELD_NAME_TL: {
        return updateStringTypeValue({
          state,
          key,
          value,
        })
      }
      case RESET_ALL: {
        return initState
      }
      default:
        return state
    }
  }

  const setFilterValuesFromUrl = createEffect((query: ParsedQuery) =>
    Object.entries(query).reduce(
      (acc: IssueTemplateListFiltersQueryType, [k, v]) => {
        if (
          Object.prototype.hasOwnProperty.call(acc, k) &&
          typeof v === 'string'
        ) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ;(acc as any)[k] = parse(
            k as keyof IssueTemplateListFiltersQueryType,
            v,
          )
        }

        return acc
      },
      clone(initState),
    ),
  )
  const updateFilter = createEvent<IssueTemplateUrlKeyType>()
  const useUpdateFilter = () => {
    const history = useHistory()

    const update = useCallback(
      (filter: IssueTemplateUrlKeyType) => {
        updateFilter(filter)
        const prev = qs.parse(window.location.search)
        const filters = filtersByQueryKey$.getState()
        const queryParams = qs.stringify(
          {
            ...prev,
            ...filters,
            assigneesTL: filters.assigneesTL.length
              ? JSON.stringify(filters.assigneesTL)
              : [],
          },
          {
            arrayFormat: 'separator',
            arrayFormatSeparator: ',',
            skipNull: true,
            skipEmptyString: true,
          },
        )
        history.push({ search: queryParams })
      },
      [history],
    )

    return { update }
  }

  const filtersByQueryKey$ = createStore<IssueTemplateListFiltersQueryType>(
    initState,
  )
    .on(setFilterValuesFromUrl.doneData, (state, newFilters) => ({
      ...state,
      ...newFilters,
    }))
    .on(updateFilter, (state, filters) => {
      let newSate = clone(state)
      //TODO поддержать кейс, когда в updateFilter закинули несколько свойств
      const entries = Object.entries(filters)
      entries.forEach(([key, value]) => {
        if (newSate) {
          newSate = insert({
            state: newSate,
            key: key as keyof IssueTemplateQueryType,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value: value as any,
          })
        }
      })

      return newSate
    })
  const appliedFilters$ = filtersByQueryKey$.map(filters =>
    Object.entries(filters).reduce((acc, [k, v]) => {
      const key = urlKeyMap[k as keyof IssueTemplateListFiltersQueryType]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(acc as any)[key as string] = v

      return acc
    }, {} as IssueTemplateListFiltersType),
  )

  return {
    setFilterValuesFromUrl,
    updateFilter,
    useUpdateFilter,
    appliedFilters$,
  }
}
