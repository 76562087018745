export const DEFAULT_DISPLAY_DATE_FORMAT = 'DD.MM.YYYY'
export const FULL_DISPLAY_DATE_FORMAT = 'D MMM YYYY HH:mm'
export const SHORT_DISPLAY_DATE_FORMAT = 'MMM D YYYY'
export const LOCAL_ORIGIN = 'http://127.0.0.1:3000'
export const DEVELOPMENT_MODE = process.env.NODE_ENV === 'development'
export const PRODUCTION_MODE = process.env.NODE_ENV === 'production'
export const ZERO_SEARCH = 'áÝé¾±µ'
export const PROJECT_IS_NOT_SELECTED_ERROR = 'Project is not selected'
export const PROJECT_URN = 'projectUrn'

export enum TabIndex {
  InstanceList,
  TemplateList,
}
