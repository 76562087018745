import {
  Button,
  Magnifier,
  TextField,
  RadioButton as RadioButtonInit,
} from '@gmini/ui-kit'
import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: calc(100% - 51px);
  flex-grow: 1;
`

export const Footer = styled.div`
  position: sticky;
  bottom: 0;
  padding: 11px 24px 12px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #e5e7f1;
  gap: 12px;
  background: #fff;
  z-index: 2;
`

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
`

type TitleProps = {
  collapsed?: boolean
}

export const Title = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: #00003d;
  white-space: nowrap;

  ${(props: TitleProps) =>
    props.collapsed &&
    `transform: rotate(90deg); color: #616184; position: absolute; top: 70px; left: 0; right: 0; font-size: 14px; font-weight: 500;`}
`

export const SectionFormWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const AddSection = styled(Button)`
  margin-top: 20px;
  margin-bottom: 20px;
`

export const SearchField = styled(TextField)`
  background: #fff !important;
  padding-left: 40px !important;
  width: 300px !important;

  &:focus {
    box-shadow: none;
  }
`
export const SearchFieldWrapper = styled.div`
  position: relative;
  margin-bottom: 8px;
`

export const SearchIcon = styled(Magnifier)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
  margin: auto 0;
`

export const SectionsContainer = styled.div`
  max-width: 800px;
  margin: 0;
`

export const RadioButton = styled(RadioButtonInit)`
  margin-bottom: -1px;
`

export const SectionFormHeader = styled.div`
  width: 800px;
  display: flex;
  align-items: center;
  background: #fff;
  margin: 0;
  justify-content: space-between;
`
