import * as chmApi from '@gmini/chm-api-sdk'

import { allowedFiltersService } from '@gmini/helpers'

export const {
  allowedFilters$,
  fetchAllowedFilters,
  fetchAllowedFiltersPending$,
} = allowedFiltersService({
  fetchAllowedFiltersApi: chmApi.Template.fetchAllowedFilters,
})
