import styled from 'styled-components'

import { IconButton } from '@gmini/ui-kit'

export const Title = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`

export const TabTitle = styled.div`
  padding: 0 10px;
`

export const BackButton = styled(IconButton)`
  margin-left: auto;

  & path {
    fill: #353b60 !important;
  }
`
