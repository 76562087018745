import { userInfo$ } from '@gmini/common'
import {
  FilterAssigneeMultiple,
  FilterDate,
  FilterPanel,
  FilterStatus,
  FilterUser,
} from '@gmini/components'
import { useStore } from 'effector-react'
import { useEffect } from 'react'

import {
  ASSIGNEES_IL,
  CREATED_DATE_CODE_IL,
  CREATED_DATE_RANGE_IL,
  ChecklistListFiltersType,
  ChecklistQueryType,
  OWNER_IDS_IL,
  STATUSES_IL,
  UPDATED_DATE_CODE_IL,
  UPDATED_DATE_RANGE_IL,
} from '@gmini/helpers'

import { PROJECT_IS_NOT_SELECTED_ERROR } from '../../../constants'

import { fetchUserListPending$, userList$ } from '../../user.store'

import {
  fetchStatusListPending$,
  instanceStatusList$,
} from '../../instanceStatus'

import { assigneeGroupList$ } from '../../assigneeGroupList'
import { fetchRoleListPending$ } from '../../role.store'

import { filterService } from '../../checklistFilter.store'

import {
  allowedFilters$,
  fetchAllowedFilters,
  fetchAllowedFiltersPending$,
} from './model'

type InstanceListFilterPanelProps = {
  onChange: (filter: ChecklistListFiltersType) => void
  projectUrn: string | null
}

export const InstanceListFilterPanel = ({
  onChange,
  projectUrn,
}: InstanceListFilterPanelProps) => {
  const {
    appliedFilters: { appliedFilters$, useUpdateFilter },
  } = filterService

  const appliedFilters = useStore(appliedFilters$)
  const { update } = useUpdateFilter()

  const allowedFilters = useStore(allowedFilters$)
  const userList = useStore(userList$)
  const fetchUserListPending = useStore(fetchUserListPending$)
  const fetchAllowFiltersPending = useStore(fetchAllowedFiltersPending$)
  const instanceStatusList = useStore(instanceStatusList$)
  const fetchStatusListPending = useStore(fetchStatusListPending$)
  const userInfo = useStore(userInfo$)
  const assigneeGroupList = useStore(assigneeGroupList$)
  const fetchRoleListPending = useStore(fetchRoleListPending$)

  useEffect(() => {
    if (!projectUrn) {
      return
    }

    fetchAllowedFilters({
      projectUrn,
    })
  }, [projectUrn])

  const onChangeHandler = (filter: ChecklistQueryType) => {
    update(filter)
    const filters = appliedFilters$.getState()
    onChange(filters)

    if (!projectUrn) {
      throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
    }

    fetchAllowedFilters({
      projectUrn,
    }) // Чтобы хэндлить изменения чек-листов другими пользователями
  }

  return (
    <FilterPanel
      items={[
        {
          title: 'Статус',
          component: (
            <FilterStatus
              dataTestIdFor={{ filterItem: { name: 'StatusBy' } }}
              allowedItems={allowedFilters?.statuses || []}
              statusList={instanceStatusList}
              loading={fetchStatusListPending}
              disabled={!allowedFilters && fetchAllowFiltersPending}
              urlKey={STATUSES_IL}
              countSkeletonItems={6}
              onChange={onChangeHandler}
              value={appliedFilters.statuses || []}
            />
          ),
        },
        {
          title: 'Дата изменения',
          component: (
            <FilterDate
              dataTestIdFor={{ filterItem: { name: 'ChangingDate' } }}
              allowedRange={allowedFilters?.updatedDateRange || null}
              urlKeyCode={UPDATED_DATE_CODE_IL}
              urlKeyValue={UPDATED_DATE_RANGE_IL}
              disabled={!allowedFilters && fetchAllowFiltersPending}
              onChange={onChangeHandler}
              value={appliedFilters.updatedDateCode}
            />
          ),
        },
        {
          title: 'Дата создания',
          component: (
            <FilterDate
              dataTestIdFor={{ filterItem: { name: 'CreationDate' } }}
              allowedRange={allowedFilters?.createdDateRange || null}
              urlKeyCode={CREATED_DATE_CODE_IL}
              urlKeyValue={CREATED_DATE_RANGE_IL}
              onChange={onChangeHandler}
              disabled={!allowedFilters && fetchAllowFiltersPending}
              value={appliedFilters.createdDateCode}
            />
          ),
        },
        {
          title: 'Автор',
          component: (
            <FilterUser
              userList={userList}
              urlKey={OWNER_IDS_IL}
              allowedItems={allowedFilters?.ownerIds || []}
              placeholder='Выбрать автора'
              disabled={
                fetchUserListPending ||
                (!allowedFilters && fetchAllowFiltersPending)
              }
              userInfo={userInfo}
              labelFirstEl='Я автор'
              onChange={onChangeHandler}
              value={appliedFilters.ownerIds || []}
            />
          ),
        },
        {
          title: 'Ответственный',
          component: (
            <FilterAssigneeMultiple
              assigneeGroupList={assigneeGroupList}
              urlKey={ASSIGNEES_IL}
              allowedItems={allowedFilters?.assignees || []}
              placeholder='Выбрать ответстве...'
              disabled={
                fetchUserListPending ||
                fetchRoleListPending ||
                (!allowedFilters && fetchAllowFiltersPending)
              }
              userInfo={userInfo}
              labelFirstEl='Назначено на меня'
              onChange={onChangeHandler}
              value={appliedFilters.assignees || []}
            />
          ),
        },
      ]}
    />
  )
}
