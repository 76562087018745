import { userInfo$ } from '@gmini/common'
import {
  FilterAssigneeMultiple,
  FilterDate,
  FilterPanel,
  FilterUser,
} from '@gmini/components'
import { useStore } from 'effector-react'
import { useEffect } from 'react'

import {
  CREATED_DATE_CODE_TL,
  CREATED_DATE_RANGE_TL,
  ChecklistTemplateListFiltersType,
  ChecklistTemplateQueryType,
  INITIATORS_TL,
  OWNER_IDS_TL,
  UPDATED_DATE_CODE_TL,
  UPDATED_DATE_RANGE_TL,
} from '@gmini/helpers'

import { PROJECT_IS_NOT_SELECTED_ERROR } from '../../../constants'
import { assigneeGroupList$ } from '../../assigneeGroupList'
import { fetchRoleListPending$ } from '../../role.store'
import { fetchUserListPending$, userList$ } from '../../user.store'

import { filterService } from '../../checklistTemplateFilter.store'

import {
  allowedFilters$,
  fetchAllowedFilters,
  fetchAllowedFiltersPending$,
} from './model'

type TemplateListFilterPanelProps = {
  onChange: (filter: ChecklistTemplateListFiltersType) => void
  projectUrn?: string | null
}

export const TemplateListFilterPanel = ({
  onChange,
  projectUrn,
}: TemplateListFilterPanelProps) => {
  const allowedFilters = useStore(allowedFilters$)
  const userList = useStore(userList$)
  const fetchUserListPending = useStore(fetchUserListPending$)
  const fetchAllowFiltersPending = useStore(fetchAllowedFiltersPending$)
  const userInfo = useStore(userInfo$)
  const assigneeGroupList = useStore(assigneeGroupList$)
  const fetchRoleListPending = useStore(fetchRoleListPending$)

  const {
    appliedFilters: { appliedFilters$, useUpdateFilter },
  } = filterService
  const appliedFilters = useStore(appliedFilters$)
  const { update } = useUpdateFilter()

  useEffect(() => {
    if (!projectUrn) {
      return
    }

    fetchAllowedFilters({
      projectUrn,
    })
  }, [projectUrn])

  const onChangeHandler = (filter: ChecklistTemplateQueryType) => {
    update(filter)
    const filters = appliedFilters$.getState()
    onChange(filters)

    if (!projectUrn) {
      throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
    }

    fetchAllowedFilters({
      projectUrn,
    }) // Чтобы хэндлить изменения замечаний другими пользователями
  }

  return (
    <FilterPanel
      items={[
        {
          title: 'Дата изменения',
          component: (
            <FilterDate
              dataTestIdFor={{ filterItem: { name: 'ChangingDate' } }}
              allowedRange={allowedFilters?.updatedDateRange || null}
              urlKeyCode={UPDATED_DATE_CODE_TL}
              urlKeyValue={UPDATED_DATE_RANGE_TL}
              disabled={!allowedFilters && fetchAllowFiltersPending}
              onChange={onChangeHandler}
              value={appliedFilters.updatedDateCode}
            />
          ),
        },
        {
          title: 'Дата создания',
          component: (
            <FilterDate
              dataTestIdFor={{ filterItem: { name: 'CreationDate' } }}
              allowedRange={allowedFilters?.createdDateRange || null}
              urlKeyCode={CREATED_DATE_CODE_TL}
              urlKeyValue={CREATED_DATE_RANGE_TL}
              onChange={onChangeHandler}
              value={appliedFilters.createdDateCode}
              disabled={!allowedFilters && fetchAllowFiltersPending}
            />
          ),
        },
        {
          title: 'Автор',
          component: (
            <FilterUser
              userList={userList}
              urlKey={OWNER_IDS_TL}
              allowedItems={allowedFilters?.ownerIds || []}
              placeholder='Выбрать автора'
              disabled={
                fetchUserListPending ||
                (!allowedFilters && fetchAllowFiltersPending)
              }
              userInfo={userInfo}
              labelFirstEl='Я автор'
              onChange={onChangeHandler}
              value={appliedFilters.ownerIds || []}
            />
          ),
        },
        {
          title: 'Инициатор чек-листа',
          component: (
            <FilterAssigneeMultiple
              assigneeGroupList={assigneeGroupList}
              urlKey={INITIATORS_TL}
              allowedItems={
                allowedFilters?.initiators.map(initiator => ({
                  ...initiator,
                  assigneeId: initiator.initiatorId,
                })) || []
              }
              placeholder='Выбрать инициато...'
              disabled={
                fetchUserListPending ||
                fetchRoleListPending ||
                (!allowedFilters && fetchAllowFiltersPending)
              }
              userInfo={userInfo}
              labelFirstEl='Я инициатор'
              onChange={onChangeHandler}
              value={appliedFilters.initiators || []}
            />
          ),
        },
      ]}
    />
  )
}
