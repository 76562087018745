import styled from 'styled-components'

type DetailsSummaryWrapperProps = {
  withBottomSeparator?: boolean
}

export const DetailsSummaryWrapper = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e5e7f1;
  ${(props: DetailsSummaryWrapperProps) =>
    props.withBottomSeparator
      ? 'border-bottom: 1px solid #e5e7f1;'
      : 'border-bottom: 0; padding-bottom: 0;'}
`

type DetailsSummaryItemProps = {
  disabled?: boolean
}

export const DetailsSummaryItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 30px;
  opacity: ${(props: DetailsSummaryItemProps) => (props.disabled ? 0.2 : 1)};
  ${(props: DetailsSummaryItemProps) => props.disabled && 'user-select: none;'}
`
