import styled from 'styled-components'

export const Container = styled.div`
  background: #f4f4f8;
  border-radius: 4px;
  padding: 22px 24px 20px 24px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`

export const RightSideHeader = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

export const QuestionCount = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: rgba(0, 3, 53, 0.5);
  white-space: nowrap;
`

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`

export const FieldRightSide = styled(FieldContainer)`
  width: 226px;
  min-width: 226px;
`

export const FormLine = styled.div`
  display: flex;
  gap: 12px;

  & + & {
    margin-top: 22px;
  }
`

export const Footer = styled.div`
  margin-top: 20px;
`

export const SelectRenderValue = styled.div`
  display: flex;
  align-items: center;
  gap: 0 6px;

  & input {
    border-color: rgba(53, 59, 96, 0.5);

    &::before {
      background: rgba(53, 59, 96, 0.5);
    }
  }
`
