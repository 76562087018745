import { userInfo$ } from '@gmini/common'
import { useStore } from 'effector-react'

import { useCallback, useEffect } from 'react'

import { PROJECT_URN, getOriginFromEnv, queryParser } from '@gmini/helpers'

import * as api from '@gmini/chm-api-sdk'

import { CreateChecklistPopup } from '../../organisms'
import { fetchTypes, fetchTypesPending$ } from '../../templateTypes.store'

import { envLinks } from '../../../config'
import { DEVELOPMENT_MODE, LOCAL_ORIGIN } from '../../../constants'
import { FormValue } from '../../organisms/CreateChecklistPopup/types'

import { fetchProjectList, projectList$ } from './model'

export const CreateChecklistPopupPage = () => {
  const projectUrn = queryParser({ key: PROJECT_URN }) as string

  const userInfo = useStore(userInfo$)
  const instanceCreatePending = useStore(
    api.Instance.create.defaultContext.pending$,
  )
  const projectList = useStore(projectList$)
  const fetchTypesPending = useStore(fetchTypesPending$)

  useEffect(() => {
    fetchTypes.submit()
    fetchProjectList.submit()
  }, [])

  const sendMessage = (checklist: api.Instance.InstancePopulated) => {
    const inspectionOrigin = getOriginFromEnv(envLinks, 'fm')

    if (window.opener) {
      window.opener.postMessage(
        checklist,
        DEVELOPMENT_MODE ? LOCAL_ORIGIN : `https://${inspectionOrigin}`,
      )
    }
  }

  const onCreateChecklist = useCallback(async (data: FormValue) => {
    try {
      const { assignees, name, project, template } = data

      if (!project) {
        throw new Error('Проект не выбран')
      }

      if (template) {
        const checklist = await api.Instance.create.defaultContext({
          templateVersion: template.version,
          templateId: template.id,
          name,
          description: '',
          assignees,
          projectUrn: project?.urn,
        })

        sendMessage(checklist)
        window.close()
      }
    } catch (error) {
      console.error('error :>> ', error)
    }
  }, [])

  if (!userInfo) {
    return null
  }

  return (
    <CreateChecklistPopup
      open
      onCreateChecklist={onCreateChecklist}
      loading={instanceCreatePending || fetchTypesPending}
      projectList={projectList}
      projectUrn={projectUrn}
      lockProjectSelect
    />
  )
}
