import {
  Button,
  TextArea as TextAreaInit,
  TextField as TextFieldInit,
} from '@gmini/ui-kit'
import styled from 'styled-components'

import { FieldContainer } from '../SectionForm/SectionForm.styled'

type ContainerProps = {
  selected: boolean | null
}

export const Container = styled.div`
  display: flex;
  background: #fff;
  border-radius: 4px;
  padding: 9px 12px 24px 22px;
  margin-top: 32px;
  border-left: ${(props: ContainerProps) =>
    props.selected ? '4px solid #4C5ECF' : '4px solid transparent'};
  transition: box-shadow ease-out 0.2s;

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }
`

export const LabelCol = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: #00003d;
  margin-right: 22px;
  margin-top: 30px;
`

export const FieldsCol = styled.div`
  flex: 1;
  padding-top: 5px;
`

export const MenuCol = styled.div`
  margin-left: 16px;
`

export const FormLine = styled.div`
  display: flex;
  gap: 12px;

  & + & {
    margin-top: 14px;
  }
`

export const AddRole = styled(Button)`
  margin-top: 24px;
`

export const FieldRightSide = styled(FieldContainer)`
  width: 162px;
  min-width: 162px;
`

export const TextArea = styled(TextAreaInit)`
  background: rgba(53, 59, 96, 0.05);

  & textarea:focus {
    background: #fff;
  }
`

export const TextFieldQuestion = styled(TextFieldInit)`
  && {
    &:disabled {
      background: rgba(53, 59, 96, 0.05);
    }
  }

  &::placeholder {
    color: rgba(0, 3, 53, 0.4);
  }
`
