import {
  Button,
  CheckMark,
  Pointer,
  //Avatar
} from '@gmini/ui-kit'

import { Dispatch, SetStateAction, useState } from 'react'

import { Scrollbars } from '@gmini/components'

import * as api from '@gmini/chm-api-sdk'

import {
  SearchField,
  SearchFieldWrapper,
  SectionFormHeader,
  SearchIcon,
} from '../../pages/EditTemplatePage/EditTemplatePage.styled'

import { Question } from '../Question'

import { SwitchSectionBlock } from '../../atoms'

import {
  Container,
  Section,
  SectionTitle,
  Description,
  SectionHeader,
  SectionName,
  QuestionList,
  SectionFooter,
  SectionActionList,
  QuestionsCompleted,
  CompletedCount,
  ConfirmCompletion,
  ConfirmCompletionText,
  ConfirmCompletionActions,
  HeaderWrapper,
} from './ChecklistForm.styled'

// import { Executor, ExecutorLabel, ExecutorValue } from './Question.styled'

type ChecklistFormProps = {
  template: api.Template.TemplatePopulated
  selectedSectionIdx: number
  setSelectedSectionIdx: Dispatch<SetStateAction<number>>
  onUpdateAnswer: (props: api.Instance.InstanceItemProperty) => void
  closeStatus: () => void
  answers: api.Instance.InstanceItemProperty[]
  instanceStatus: api.InstanceStatus.Enum
  editorPending: boolean
  instanceVersion?: number
  isPreview?: boolean
}

export const ChecklistForm = ({
  template,
  selectedSectionIdx,
  setSelectedSectionIdx,
  onUpdateAnswer,
  closeStatus,
  answers,
  instanceStatus,
  editorPending,
  instanceVersion,
  isPreview = false,
}: ChecklistFormProps) => {
  const [isConfirmVisible, setIsConfirmVisible] = useState(false)
  const selectedSection = template.sections[selectedSectionIdx]
  const isChecklistCompleted =
    template.sections.reduce(
      (acc, section) => acc + (section?.items?.length || 0),
      0,
    ) === answers.length
  const questionsIds = selectedSection?.items?.map(question => question.id)
  const answersIds = answers.map(answer => answer.templateSectionItemId)
  const questionsCompleted =
    questionsIds?.filter(questionId => answersIds.includes(questionId))
      .length || 0

  return (
    <Container>
      <Scrollbars>
        {selectedSection?.items?.length ? (
          <Section>
            <HeaderWrapper>
              <SectionFormHeader>
                <SearchFieldWrapper>
                  <SearchField
                    // eslint-disable-next-line no-empty-function
                    onChange={() => {}}
                    value=''
                    placeholder='Поиск вопроса или секции'
                    disabled
                  />
                  <SearchIcon />
                </SearchFieldWrapper>
              </SectionFormHeader>
              <SectionHeader>
                <SectionName>
                  <SectionTitle>
                    {selectedSectionIdx + 1}. {selectedSection?.name}
                  </SectionTitle>
                </SectionName>
              </SectionHeader>
            </HeaderWrapper>
            <Description>{selectedSection?.description}</Description>

            <QuestionList>
              {selectedSection?.items?.map((question, idx) => (
                <Question
                  index={`1.${idx + 1}`}
                  key={question.id}
                  question={question}
                  onUpdateAnswer={onUpdateAnswer}
                  itemProperty={answers.find(
                    answer => answer.templateSectionItemId === question.id,
                  )}
                  instanceStatus={instanceStatus}
                  instanceVersion={instanceVersion}
                  isPreview={isPreview}
                />
              ))}
            </QuestionList>

            <SectionFooter>
              {/* <Executor>
              <Avatar />
              <ExecutorValue>Петров П. П. ООО “Строитель”</ExecutorValue>
              <ExecutorLabel>/ Прораб</ExecutorLabel>
            </Executor> */}
              <QuestionsCompleted>
                Вопросов выполнено:{' '}
                <CompletedCount>
                  {questionsCompleted}/{selectedSection?.items?.length}
                </CompletedCount>
              </QuestionsCompleted>
              <SectionActionList>
                <Button
                  size='regular'
                  leftIcon={<CheckMark />}
                  disabled={
                    !isChecklistCompleted ||
                    editorPending ||
                    instanceStatus === api.InstanceStatus.Enum.FINISHED
                  }
                  onClick={() => setIsConfirmVisible(true)}
                >
                  Выполнено
                </Button>
                <ConfirmCompletion hidden={!isConfirmVisible}>
                  <ConfirmCompletionText>
                    Вы действительно хотите завершить чек-лист?
                  </ConfirmCompletionText>
                  <ConfirmCompletionActions>
                    <Button
                      color='primary'
                      onClick={() => {
                        setIsConfirmVisible(false)
                        closeStatus()
                      }}
                      size='regular'
                    >
                      Завершить
                    </Button>
                    <Button
                      size='regular'
                      color='secondary'
                      onClick={() => setIsConfirmVisible(false)}
                    >
                      Отмена
                    </Button>
                  </ConfirmCompletionActions>
                </ConfirmCompletion>
              </SectionActionList>
            </SectionFooter>
          </Section>
        ) : (
          <h1 style={{ marginBottom: '40px' }}>
            В секции нет вопросов для отображения
          </h1>
        )}
        {template.sections && template.sections.length > 1 ? (
          <SwitchSectionBlock>
            <Button
              disabled={selectedSectionIdx === 0}
              size='regular'
              color='secondary'
              leftIcon={<Pointer />}
              onClick={() => setSelectedSectionIdx(prevIdx => prevIdx - 1)}
            >
              Предыдущая секция
            </Button>
            <Button
              size='regular'
              color='secondary'
              disabled={template.sections.length - 1 === selectedSectionIdx}
              rightIcon={<Pointer style={{ transform: ' rotate(180deg)' }} />}
              onClick={() => setSelectedSectionIdx(prevIdx => prevIdx + 1)}
            >
              Следующая секция
            </Button>
          </SwitchSectionBlock>
        ) : null}
      </Scrollbars>
    </Container>
  )
}
