import {
  Button,
  Eye,
  IconButton,
  PlusCircleFill,
  Pointer,
  Tooltip,
} from '@gmini/ui-kit'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useStore, useStoreMap } from 'effector-react'

import { useHistory, useParams } from 'react-router'

import * as smApi from '@gmini/sm-api-sdk'

import {
  Scrollbars,
  SwitchProjectBadgeWrapper,
  TextFieldWithPreview,
} from '@gmini/components'

import { ProjectBadge } from '@gmini/common'

import qs from 'query-string'

import { SELECTED_TAB } from '@gmini/helpers'

import { editorPending$ } from '../../editorPending'

import { TabIndex } from '../../../constants'

import { Scheme } from '../../molecules'
import {
  ChecklistManagerWrapper,
  SectionForm,
  TemplateDetailsPanel,
  usePreviewChecklistDialog,
} from '../../organisms'

import {
  ContentLeftPanelEditPage,
  HeaderLeftPanelEditPage,
  LeftPanelEditPage,
  SwitchSectionBlock,
} from '../../atoms'

import {
  fetchMostRecentTemplate,
  templateByIds$,
  updateTemplate,
} from '../../pages/EditTemplatePage/EditTemplate.store'
import { createSection } from '../../sectionCRUD.store'

import {
  fetchProjectList,
  fetchProjectListPending$,
  projectList$,
} from './model'

import {
  AddSection,
  Container,
  Content,
  Footer,
  SearchField,
  SearchFieldWrapper,
  SearchIcon,
  SectionFormHeader,
  SectionFormWrapper,
  SectionsContainer,
} from './EditTemplatePage.styled'

export const EditTemplatePage = () => {
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const id = Number(params.id)

  const [editing, setEditing] = useState(false)

  const onToggleEditing = useCallback((value: boolean) => {
    setEditing(value)
  }, [])

  const template = useStoreMap({
    store: templateByIds$,
    keys: [id],
    fn: (templatesByIds, [id]) =>
      templatesByIds && id && id in templatesByIds ? templatesByIds[id] : null,
  })

  const [selectedSectionIdx, setSelectedSectionIdx] = useState(0)
  const [delayedCreateSection, setDelayedCreateSection] = useState(false)
  const createSectionPending = useStore(createSection.pending$)
  const editorPending = useStore(editorPending$)
  const projectList = useStore(projectList$)
  const fetchProjectListPending = useStore(fetchProjectListPending$)

  const selectedProject = useMemo(
    () => projectList.find(({ urn }) => urn === template?.projectUrn) || null,
    [template?.projectUrn, projectList],
  )

  const createEmptySection = useCallback(
    ({
      templateId,
      templateVersion,
    }: {
      templateId: smApi.Id
      templateVersion: smApi.Version
    }) => {
      createSection({
        templateId,
        templateVersion,
        section: {
          description: '',
          name: 'Новая секция',
          weight: 0,
        },
      })
    },
    [],
  )

  useEffect(() => {
    if (!id) {
      return
    }

    fetchMostRecentTemplate({ id })
  }, [id])

  useEffect(() => {
    const createQuestionSubscription = fetchMostRecentTemplate.doneData.watch(
      ({ version }) => {
        if (version && delayedCreateSection) {
          createEmptySection({ templateId: id, templateVersion: version })
          setDelayedCreateSection(false)
        }
      },
    )

    return () => {
      createQuestionSubscription.unsubscribe()
    }
  }, [createEmptySection, delayedCreateSection, id])

  useEffect(() => {
    fetchProjectList.submit()
  }, [])

  useEffect(() => {
    const toggleEditingSubscription = fetchMostRecentTemplate.doneData.watch(
      () => {
        onToggleEditing(false)
      },
    )

    return () => {
      toggleEditingSubscription.unsubscribe()
    }
  }, [onToggleEditing])

  const { PreviewChecklistDialog, onOpen } = usePreviewChecklistDialog({
    templatePopulated: template,
  })

  if (!id || !template) {
    return null
  }

  const onCreateSection = () => {
    if (editing) {
      setDelayedCreateSection(true)
      return
    }
    createEmptySection({ templateId: id, templateVersion: template.version })
  }

  const onChangeNameTemplate = (value: string) => {
    updateTemplate({
      ...template,
      id,
      version: template.version,
      name: value,
    })
  }

  const existQuestions = template.sections.some(
    section => section.items?.length,
  )

  const goBack = () => {
    const prev = qs.parse(window.location.search)
    const search = qs.stringify({
      ...prev,
      [SELECTED_TAB]: TabIndex.TemplateList.toString(),
    })

    history.push({
      pathname: '/',
      search,
    })
  }

  return (
    <ChecklistManagerWrapper
      selectedProject={selectedProject}
      brandSecondary={
        <SwitchProjectBadgeWrapper>
          <ProjectBadge
            projectName={selectedProject?.name || ''}
            loading={fetchProjectListPending}
          />
        </SwitchProjectBadgeWrapper>
      }
    >
      {PreviewChecklistDialog}
      <Container>
        <Content>
          <LeftPanelEditPage>
            <HeaderLeftPanelEditPage>
              <IconButton onClick={goBack}>
                <Pointer color='rgba(53, 59, 96, 0.5)' />
              </IconButton>

              <TextFieldWithPreview
                value={template.name}
                onChange={onChangeNameTemplate}
                disabled={editorPending}
              />
            </HeaderLeftPanelEditPage>
            <ContentLeftPanelEditPage>
              <Scheme
                sections={template.sections}
                onCreateSection={onCreateSection}
                title='Схема шаблона'
                selectedSectionIdx={selectedSectionIdx}
                setSelectedSectionIdx={setSelectedSectionIdx}
              />
              <SectionFormWrapper>
                <SectionFormHeader>
                  <SearchFieldWrapper>
                    <SearchField
                      // eslint-disable-next-line no-empty-function
                      onChange={() => {}}
                      value=''
                      placeholder='Поиск вопроса или секции'
                      disabled
                    />
                    <SearchIcon />
                  </SearchFieldWrapper>
                  <Tooltip
                    title={!existQuestions ? 'Необходимо добавить вопрос' : ''}
                  >
                    <Button
                      onClick={onOpen}
                      style={{ marginLeft: 'auto' }}
                      leftIcon={<Eye />}
                      disabled={!existQuestions}
                      color='secondary'
                    >
                      Предпросмотр чек-листа
                    </Button>
                  </Tooltip>
                </SectionFormHeader>

                <Scrollbars>
                  <SectionsContainer>
                    {template.sections[selectedSectionIdx] ? (
                      <SectionForm
                        section={template.sections[selectedSectionIdx]}
                        key={template.sections[selectedSectionIdx].id}
                        templateVersion={template.version}
                        templateId={id}
                        sectionIdx={selectedSectionIdx}
                        disabled={editorPending}
                        onToggleEditing={onToggleEditing}
                        editing={editing}
                        setSelectedSectionIdx={setSelectedSectionIdx}
                      />
                    ) : null}

                    <AddSection
                      size='small'
                      color='tertiary'
                      onClick={onCreateSection}
                      disabled={createSectionPending}
                      leftIcon={<PlusCircleFill />}
                    >
                      Добавить секцию
                    </AddSection>
                  </SectionsContainer>
                  {template.sections.length > 1 ? (
                    <SwitchSectionBlock>
                      <Button
                        disabled={selectedSectionIdx === 0}
                        size='regular'
                        color='secondary'
                        leftIcon={<Pointer />}
                        onClick={() =>
                          setSelectedSectionIdx(prevIdx => prevIdx - 1)
                        }
                      >
                        Предыдущая секция
                      </Button>
                      <Button
                        size='regular'
                        color='secondary'
                        disabled={
                          template.sections.length - 1 === selectedSectionIdx
                        }
                        rightIcon={
                          <Pointer style={{ transform: ' rotate(180deg)' }} />
                        }
                        onClick={() =>
                          setSelectedSectionIdx(prevIdx => prevIdx + 1)
                        }
                      >
                        Следующая секция
                      </Button>
                    </SwitchSectionBlock>
                  ) : null}
                </Scrollbars>
              </SectionFormWrapper>
            </ContentLeftPanelEditPage>
          </LeftPanelEditPage>
          <TemplateDetailsPanel
            template={template}
            id={id}
            version={template.version}
            disabled={editorPending}
            onToggleEditing={onToggleEditing}
            editing={editing}
          />
        </Content>

        <Footer>
          <Button size='regular' color='secondary' onClick={goBack}>
            Выйти
          </Button>
        </Footer>
      </Container>
    </ChecklistManagerWrapper>
  )
}
