import styled, { css } from 'styled-components'

export type InstanceStatusRootProps = {
  bg: string
}

export const InstanceStatusRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 7px;
  padding-right: 7px;
  height: 24px;
  border-radius: 20px;
  background: #f5a623;

  ${(p: InstanceStatusRootProps) => css`
    background: ${p.bg};
  `}
`

export const InstanceStatusText = styled.span`
  margin-top: 2px;
  margin-right: 5px;
  color: #fff;
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
`
