import { createStore } from 'effector'

import * as api from '@gmini/chm-api-sdk'

export const fetchTypes = api.Template.fetchTypes.createContext()
export const fetchTypesPending$ = fetchTypes.pending$

export const templateTypes$ = createStore<api.Template.TemplateType[]>([]).on(
  fetchTypes.doneData,
  (state, result) => {
    if (result) {
      return result
    }

    return state
  },
)
