export const getOriginFromEnv = (envLinks: string, key: string) => {
  try {
    const origin: string = JSON.parse(envLinks)?.[key]

    if (!origin) {
      throw new Error(
        `В переменной окружения SPA_LINKS_CONFIG не найден ключ ${key}`,
      )
    }
    return origin
  } catch (error) {
    console.error(error)
  }
}
