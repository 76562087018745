import { LinearProgressDeterminate } from '@gmini/ui-kit/lib/LinearProgressDeterminate'
import styled, { css } from 'styled-components'

type LinearProgressBarProps = {
  colorBar?: string
}

export const LinearProgressBar = styled(LinearProgressDeterminate)`
  border-radius: 10px;
  height: 6px;
  background: rgba(0, 10, 87, 0.07);

  ${(props: LinearProgressBarProps) =>
    props.colorBar &&
    css`
      color: ${props.colorBar};
    `}
`
