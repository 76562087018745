import { useState, useEffect, useRef, useCallback } from 'react'

import { Pen, Cancel, CheckMark, Tooltip, LongText } from '@gmini/ui-kit'

import {
  Name,
  EditButton,
  ButtonsWrapper,
  Input,
  CancelButton,
  DoneButton,
  NameText,
} from './TextFieldWithPreview.styled'

type TextFieldWithPreviewProps = {
  value: string
  onChange?: (value: string) => void
  renderValue?: (value: string) => string
  disabled?: boolean
  visibleEditIcon?: boolean
  autoFocus?: boolean
  variant?: 'default' | 'editIssueTitle' | 'editIssueSmall'
  placeholder?: string
  minWidth?: string
}

export const TextFieldWithPreview = ({
  value,
  onChange,
  renderValue,
  disabled,
  visibleEditIcon,
  autoFocus = false,
  variant = 'default',
  placeholder,
  minWidth = '340px',
}: TextFieldWithPreviewProps) => {
  const [editable, setEditable] = useState(autoFocus)
  const [nameState, setName] = useState(value)
  const inputRef = useRef<HTMLInputElement>(null)

  const trimmedNameLength = nameState.trim().length

  useEffect(() => {
    setName(value)
  }, [value])

  useEffect(() => {
    if (editable) {
      inputRef.current?.focus()
    }
  }, [editable])

  const onKeyDown = useCallback(
    event => {
      if (editable && nameState !== value) {
        switch (event.key) {
          case 'Tab':
          case 'Enter':
            if (
              (trimmedNameLength > 0 || variant === 'editIssueSmall') &&
              trimmedNameLength < 257
            ) {
              onChange?.(nameState.trim())
              setEditable(false)
            }
            break

          case 'Escape':
            setName(value)
            setEditable(false)
            break
        }
      }
    },
    [editable, nameState, onChange, value, variant, trimmedNameLength],
  )

  const isEmpty = variant === 'editIssueSmall' ? false : trimmedNameLength < 1

  const isOverfilled = trimmedNameLength > 256

  return (
    <Name variant={variant}>
      {editable ? (
        <Input
          variant={variant}
          value={nameState}
          placeholder={placeholder}
          onChange={event => setName(event.target.value)}
          ref={inputRef}
          onKeyDown={onKeyDown}
          minWidth={minWidth}
        />
      ) : (
        <NameText>
          {value ? (
            <LongText withoutRightText partSize={30}>
              {renderValue ? renderValue(value) : value}
            </LongText>
          ) : (
            <div style={{ color: 'rgba(0, 0, 61, 0.6)' }}>{placeholder}</div>
          )}
        </NameText>
      )}
      <ButtonsWrapper variant={variant}>
        {editable ? (
          <>
            <CancelButton
              onClick={() => {
                setEditable(false)
              }}
              disabled={disabled}
              size={variant === 'default' ? 'small' : 'xs'}
              type='circle'
            >
              <Cancel />
            </CancelButton>
            <Tooltip
              title={
                isEmpty
                  ? 'Введите название'
                  : isOverfilled
                  ? 'Название не должно превышать 256 символов'
                  : ''
              }
            >
              <DoneButton
                onClick={() => {
                  setEditable(false)
                  onChange?.(nameState.trim())
                }}
                disabled={disabled || isEmpty || isOverfilled}
                size={variant === 'default' ? 'small' : 'xs'}
                type='circle'
              >
                <CheckMark />
              </DoneButton>
            </Tooltip>
          </>
        ) : (
          <EditButton
            visible={visibleEditIcon}
            onClick={() => setEditable(true)}
            disabled={disabled}
            size={variant === 'default' ? 'small' : 'xs'}
          >
            <Pen />
          </EditButton>
        )}
      </ButtonsWrapper>
    </Name>
  )
}
