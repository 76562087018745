import styled from 'styled-components'
import { TextArea as TextAreaInit } from '@gmini/ui-kit'

export const Container = styled.div`
  padding: 21px 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`

export const FormWrapper = styled.div`
  width: 450px;
  margin: 20px auto 0 auto;

  @media (max-width: 450px) {
    width: 100%;
  }
`

export const FormTitle = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: #00003d;
`

type FieldLabelProps = {
  required?: boolean
}

export const FieldLabel = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: #000335;
  margin-bottom: 6px;

  &:after {
    ${(props: FieldLabelProps) => props.required && 'content: " *";'}
    color: #de4242;
    font-size: 13px;
    line-height: 20px;
    font-weight: bold;
    font-family: Roboto, sans-serif;
  }
`

export const FieldContainer = styled.div`
  width: 100%;

  &:not(:first-child) {
    margin-top: 14px;
  }
`

export const FooterForm = styled.div`
  margin-top: 40px;
`

export const Subtitle = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #000335;
`

export const SwitcherSection = styled.div`
  padding: 24px 0 32px 0;
  width: 100%;
  border-top: 1px solid #e5e7f1;
  border-bottom: 1px solid #e5e7f1;
  margin-top: 30px;
`

export const SwitcherSectionRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 24px;
  }
`

export const AutoCreateLabel = styled.div`
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: rgba(0, 3, 53, 0.25);
`

export const IssuesLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: rgba(76, 94, 207, 0.2);
`

export const SettingTemplateContainer = styled.div`
  width: 100%;
`

export const SettingTemplateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`
export const SettingTemplateItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const TextArea = styled(TextAreaInit)`
  && {
    background: rgba(53, 59, 96, 0.05);
  }
  &:focus-within {
    background: #fff;
  }
`
