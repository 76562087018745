import { useState } from 'react'

import * as api from '@gmini/chm-api-sdk'

import { PreviewChecklistDialog } from './PreviewChecklistDialog'

type UsePreviewChecklistDialog = {
  onClose?: () => void
  open?: boolean
  templatePopulated: api.Template.TemplatePopulated | null
}

export const usePreviewChecklistDialog = (
  props: UsePreviewChecklistDialog,
): {
  onClose: () => void
  onOpen: () => void
  open: boolean
  PreviewChecklistDialog: JSX.Element | null
} => {
  const [open, setOpen] = useState(false)

  const onClose = () => {
    setOpen(false)
  }

  const onOpen = () => {
    setOpen(true)
  }

  return {
    open,
    onClose,
    onOpen,
    PreviewChecklistDialog: (
      <PreviewChecklistDialog open={open} onClose={onClose} {...props} />
    ),
  }
}
