import { ReactNode } from 'react'

import { Inactive, InactiveWrapper, Wrapper, Label } from './RadioButton.styled'

type RadioButtonProps = {
  active?: boolean
  disabled?: boolean
  onClick?: () => void
  label?: ReactNode
  icon?: ReactNode
}

export const RadioButton = ({
  active,
  disabled,
  onClick,
  label,
  icon,
}: RadioButtonProps) => (
  <Wrapper disabled={disabled} onClick={onClick}>
    {active ? (
      <>
        {icon}
        <Label>{label}</Label>
      </>
    ) : (
      <>
        <InactiveWrapper>
          <Inactive disabled={disabled} />
        </InactiveWrapper>
        <Label>{label}</Label>
      </>
    )}
  </Wrapper>
)
