import { IconButton } from '@gmini/ui-kit'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
  z-index: 1200;
`

export const PreviewHeader = styled.div`
  background: #4c5ecf;
  height: 65px;
  width: 100%;
  padding-left: 24px;
  padding-right: 20px;
  display: flex;
  align-items: center;
`

export const PreviewHeaderTitle = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: #ffffff;
`

export const PreviewChildrenWrapper = styled.div`
  box-shadow: 0px 14px 20px rgba(1, 1, 92, 0.15);
  border-radius: 4px;
  width: calc(100% - 8px);
  height: 100%;
  margin: 0 auto;
  background: #fff;
`

export const ClosePreviewButton = styled(IconButton)`
  margin-left: auto;

  & svg path {
    fill: #fff;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  &:active {
    background: rgba(255, 255, 255, 0.2);
  }
`

export const PreviewBgWrapper = styled.div`
  height: calc(100% - 65px);
  background: #4c5ecf;
  padding-bottom: 4px;
`

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
`
