import {
  passesFilterAssigneeMultiple,
  passesFilterDate,
  passesFilterStatus,
  passesFilterType,
  passesFilterUser,
} from '@gmini/components'
import { ChecklistListFiltersType, filterDateEnrichment } from '@gmini/helpers'
import * as api from '@gmini/chm-api-sdk'

export const matchInstanceToFilters = (
  instance: api.Instance.InstancePopulated,
  filters: ChecklistListFiltersType,
) => {
  const isPassAssigneeFilter = passesFilterAssigneeMultiple(
    filters.assignees,
    instance.assignees,
  )

  const isPassOwnerFilter = passesFilterUser(
    filters.ownerIds || [],
    instance.ownerId,
  )

  const isPassTypeFilter = passesFilterType(
    filters.templateTypeIds,
    instance.templateTypeId,
  )

  const isPassStatusFilter = passesFilterStatus(
    filters.statuses,
    instance.status,
  )

  const currentDate = new Date()

  const { enrichedUpdatedDateRange, enrichedCreatedDateRange } =
    filterDateEnrichment(currentDate, filters)

  const isPassUpdatedDateFilter = passesFilterDate(
    enrichedUpdatedDateRange,
    instance.updatedAt,
  )

  const isPassCreatedDateFilter = passesFilterDate(
    enrichedCreatedDateRange,
    instance.createdAt,
  )

  const isPassSearchFilter = instance.name
    .toLowerCase()
    .includes(filters.filter.toLowerCase())

  return (
    isPassAssigneeFilter &&
    isPassTypeFilter &&
    isPassStatusFilter &&
    isPassUpdatedDateFilter &&
    isPassCreatedDateFilter &&
    isPassSearchFilter &&
    isPassOwnerFilter
  )
}
