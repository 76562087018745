import { Sections, Sheet, Sign } from '@gmini/ui-kit'

import { declensionsOfNum } from '@gmini/utils'

import {
  DetailsSummaryWrapper,
  DetailsSummaryItem,
} from './DetailsSummary.styled'

export type DetailsSummaryProps = {
  countSection: number
  questionCount: number
  signCount: number
  sectionOutOf?: number
  questionOutOf?: number
  signOutOf?: number
  withBottomSeparator?: boolean
  //TODO разбить на несколько компонентов и убрать все лишние поля
}

export const DetailsSummary = ({
  countSection,
  questionCount,
  sectionOutOf,
  questionOutOf,
  signCount,
  signOutOf,
  withBottomSeparator = true,
}: DetailsSummaryProps) => (
  <DetailsSummaryWrapper withBottomSeparator={withBottomSeparator}>
    <DetailsSummaryItem>
      <Sections />
      {countSection}
      {typeof sectionOutOf === 'number' && `/${sectionOutOf}`}{' '}
      {declensionsOfNum(countSection, ['Cекция', 'Cекции', 'Cекций'])}
    </DetailsSummaryItem>
    <DetailsSummaryItem>
      <Sheet />
      {questionCount}
      {typeof questionOutOf === 'number' && `/${questionOutOf}`}{' '}
      {declensionsOfNum(questionCount, ['Вопрос', 'Вопроса', 'Вопросов'])}
    </DetailsSummaryItem>
    <DetailsSummaryItem disabled>
      <Sign />
      {signCount}
      {typeof signOutOf === 'number' && `/${signOutOf}`}{' '}
      {declensionsOfNum(0, ['Подпись', 'Подписи', 'Подписей'])}
    </DetailsSummaryItem>
  </DetailsSummaryWrapper>
)
