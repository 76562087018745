import { combine } from 'effector'

import {
  updateAnswer,
  updateInstance,
} from './pages/EditInstancePage/EditInstance.store'

import {
  createTemplatePending$,
  deleteTemplatePending$,
} from './organisms/TemplateList/model'

import {
  fetchMostRecentTemplate,
  fetchTemplate,
  updateTemplate,
} from './pages/EditTemplatePage/EditTemplate.store'

import {
  createSection,
  deleteSection,
  updateSection,
} from './sectionCRUD.store'

import {
  createQuestion,
  deleteQuestion,
  updateQuestion,
} from './questionCRUD.store'
import {
  deleteSectionItemPending$,
  downloadSectionItemFilePending$,
  fetchSectionItemFileListPending$,
  uploadSectionItemPending$,
} from './file.store'

export const editorPending$ = combine(
  [
    fetchMostRecentTemplate.pending$,
    fetchTemplate.pending$,
    createTemplatePending$,
    updateTemplate.pending$,
    deleteTemplatePending$,
    createSection.pending$,
    updateSection.pending$,
    deleteSection.pending$,
    createQuestion.pending$,
    updateQuestion.pending$,
    deleteQuestion.pending$,
    updateInstance.pending$,
    updateAnswer.pending$,
    uploadSectionItemPending$,
    deleteSectionItemPending$,
    downloadSectionItemFilePending$,
    fetchSectionItemFileListPending$,
  ],
  pendings => pendings.some(Boolean),
)
