import styled, { css } from 'styled-components'

import { Triangle as TriangleInit } from '@gmini/ui-kit'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0 5px;
  cursor: pointer;
`

export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4c5ecf;
  letter-spacing: 0.15px;
`

type TriangleProps = {
  isRotated?: boolean
}

export const Triangle = styled(TriangleInit)`
  ${(props: TriangleProps) =>
    props.isRotated &&
    css`
      transform: rotate(180deg);
    `}

  width: 11px;
  height: 11px;
`
