import * as api from '@gmini/chm-api-sdk'

import { createTemplateListService } from '../../createTemplateListService'

export const {
  fetchTemplateList,
  resetTemplateList,
  templateList$,
  fetchTemplateListPending$,
} = createTemplateListService({
  createTemplateApi: api.Template.createTemplate,
  deleteTemplateApi: api.Template.deleteTemplate,
  fetchTemplateListApi: api.Template.fetchList,
})
