import { LinearProgressDeterminate as LinearProgress } from '@gmini/ui-kit/lib/LinearProgressDeterminate'
import styled from 'styled-components'

export const ProgressBar = styled(LinearProgress)`
  border-radius: 10px;
  background: rgba(0, 10, 87, 0.07);
  height: 6px;
  margin-top: 26px;
`

export const Separator = styled.div`
  background: #e5e7f1;
  height: 1px;
  width: 100%;
  margin: 24px 0;
`

export const DateBlock = styled.div`
  display: flex;
  gap: 20px;
  padding-top: 22px;
`

export const DateBlockItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const ProgressDescriptionLabel = styled.div`
  color: rgba(0, 3, 53, 0.4);
  font-size: 13px;
  line-height: 20px;
`

export const ProgressDescriptionValue = styled.div`
  color: #000335;
  font-size: 13px;
  line-height: 20px;
`

export const ProgressDescription = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  gap: 4px;
`

export const DetailsFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 100%;
`

export const InactiveTextArea = styled.div`
  padding: 9.5px 8px;
  background: #fff;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border-radius: 4px;
  color: #353b60;
  word-break: break-word;
`
