import {
  IconButton,
  UnfoldMore,
  UnfoldLess,
  Sections,
  PlusCircle,
  Sign,
  LongText,
} from '@gmini/ui-kit'
import { Dispatch, SetStateAction, useState } from 'react'

import * as api from '@gmini/chm-api-sdk'

import {
  Container,
  Header,
  SchemeItem,
  SectionList,
  AddButton,
  ExternalSection,
  SchemeItemText,
  Count,
  SchemeTitle,
} from './Scheme.styled'

type SchemeProps = {
  sections: api.Template.Section[] | undefined
  onCreateSection: () => void
  title?: string
  selectedSectionIdx: number
  setSelectedSectionIdx: Dispatch<SetStateAction<number>>
  inInstance?: boolean
}

export const Scheme = ({
  sections,
  onCreateSection,
  title,
  selectedSectionIdx,
  setSelectedSectionIdx,
  inInstance,
}: SchemeProps) => {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <Container collapsed={collapsed}>
      <Header>
        <SchemeTitle collapsed={collapsed}>{title}</SchemeTitle>
        <IconButton onClick={() => setCollapsed(prev => !prev)}>
          {collapsed ? <UnfoldMore /> : <UnfoldLess />}
        </IconButton>
      </Header>
      {!collapsed && (
        <>
          <SectionList>
            {sections?.map((section, idx) => (
              <SchemeItem
                selected={sections?.[selectedSectionIdx]?.id === section.id}
                key={section.id}
                onClick={() => setSelectedSectionIdx(idx)}
              >
                <Sections />
                <SchemeItemText>
                  {idx + 1}.{' '}
                  <LongText withoutRightText partSize={30}>
                    {section.name}
                  </LongText>
                </SchemeItemText>
                {section.items?.length ? (
                  <Count>{section.items?.length}</Count>
                ) : (
                  ''
                )}
              </SchemeItem>
            ))}
          </SectionList>
          {!inInstance ? (
            <AddButton
              size='small'
              color='tertiary'
              leftIcon={<PlusCircle />}
              onClick={onCreateSection}
            >
              Добавить секцию
            </AddButton>
          ) : null}
          <ExternalSection>
            <Sign />
            Обязательные подписи
          </ExternalSection>
        </>
      )}
    </Container>
  )
}
