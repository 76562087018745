import styled from 'styled-components'
import { LinearProgressDeterminate as LinearProgressInit } from '@gmini/ui-kit/lib/LinearProgressDeterminate'

export const Container = styled.div`
  display: flex;
  gap: 35px;
  height: 100%;
  flex-grow: 1;
`

export const Section = styled.div`
  position: relative;
  padding: 0 24px;
  margin: 0;
  margin-bottom: 40px;
  background: #f4f4f8;
  width: 800px;
  border-radius: 4px;
`

export const SectionTitle = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #00003d;
  letter-spacing: 0.15px;
  word-break: break-word;
`

export const Badge = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 6px 12px;
  background: rgba(53, 59, 96, 0.15);
  border-radius: 0 4px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
`

export const BadgeTitle = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
`

export const Description = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 131%;
  color: #00003d;
  letter-spacing: 0.15px;
  padding-left: 24px;
  position: relative;
  z-index: 1;
  background: #f4f4f8;
  margin: 0 -24px;
`

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
  margin: 0 -24px;
`

export const SectionHeader = styled.div`
  padding: 24px;
  padding-bottom: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  z-index: 2;
  background: #f4f4f8;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`

export const SectionName = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProgressBarWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 10px;
`

export const ProgressBarTitle = styled.div`
  display: flex;
  gap: 4px;
`

export const ProgressBarLabel = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: rgba(0, 3, 53, 0.5);
`

export const ProgressBarValue = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #000335;
`

export const ProgressBar = styled(LinearProgressInit)`
  background: rgba(0, 10, 87, 0.07);
  border-radius: 10px;
  height: 6px;
  color: #4c5ecf;
`

export const QuestionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  margin-top: 24px;
`

export const SectionFooter = styled.div`
  position: sticky;
  bottom: 0;
  background: #f4f4f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px -24px 0 -24px;
  padding: 24px;
  border-top: 1px solid rgba(53, 59, 96, 0.1);
  box-shadow: 0px -9px 14px -2px rgba(53, 59, 96, 0.11);
  clip-path: polygon(0 -1000%, 100% -1000%, 100% 100%, 0 100%);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
`

export const QuestionsCompleted = styled.div`
  font-weight: 500;
  line-height: 150%;
  color: #353b60;
`

export const CompletedCount = styled.span`
  color: rgba(53, 59, 96, 0.6);
  font-weight: 400;
`

export const SectionActionList = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
`

export const ConfirmCompletion = styled.div`
  width: max-content;
  position: absolute;
  bottom: calc(100% + 4px);
  right: 0;
  padding: 24px 24px 20px 24px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 9px 30px rgb(53 59 96 / 30%);
`

export const ConfirmCompletionActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 12px;
`

export const ConfirmCompletionText = styled.div`
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: rgb(53, 59, 96);
`
